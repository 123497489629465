import { useEffect, useState } from "react";

/**
 * @typedef {object} UseResizeObserverReturn
 * @property {number} height
 * @property {number} scrollHeight
 */

/**
 * @typedef {object} Props
 * @property {import("react").MutableRefObject<HTMLElement | null>} [ref] injected ref if needed
 */
/**
 * @param {Props} props
 * @returns {UseResizeObserverReturn}
 */
export function useResizeObserver({ ref }) {
  const [values, setValues] = useState({ height: 0, scrollHeight: 0 });

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        setValues((currentValues) => {
          const newValues = {
            height: entry.contentRect.height,
            scrollHeight: entry.target.scrollHeight,
          };
          if (JSON.stringify(currentValues) !== JSON.stringify(newValues)) {
            return newValues;
          }

          return currentValues;
        });
      }
    });

    let currentRef = ref?.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [ref]);

  return values;
}
