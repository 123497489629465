import { Box, Stack } from "@chakra-ui/react";
import ApiErrorMessage from "@raiden/library-ui/components/ApiErrorMessage";
import InputPhone from "@raiden/library-ui/components/Form/InputPhone";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useMemo } from "react";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("./Handler").WebHotelContactCreateFormValues>} form
 */
/**
 * @param {Props} props
 */
export function WebHotelContactCreateForm({ form }) {
  const intl = useIntl();

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/Country").Country[]>} */
  const { swrResponse: swrResponseCountries } = useApi(
    generateApiUrl({ id: "@countries.search", query: { per_page: 250 } }),
  );

  const countries = useMemo(() => {
    return swrResponseCountries.data?.data ?? [];
  }, [swrResponseCountries.data?.data]);

  return (
    <Stack spacing="1rem">
      <Box>
        <ApiErrorMessage withCaughtErrors={true} />

        <RGrid minCellWidth="300px">
          <FormControlRHF
            control={form.control}
            name="data.first_name"
            rules={{ required: true }}
            type="text"
            label={intl.formatMessage({ defaultMessage: "Prénom" })}
          />

          <FormControlRHF
            control={form.control}
            name="data.last_name"
            rules={{ required: true }}
            type="text"
            label={intl.formatMessage({ defaultMessage: "Nom" })}
          />
        </RGrid>
      </Box>

      <InputPhone
        countries={countries}
        nameCountry="data.phone_country"
        nameNumber="data.phone_number"
      />

      <RGrid minCellWidth="300px">
        <FormControlRHF
          control={form.control}
          name="data.email"
          type="email"
          rules={{
            required: true,
            onChange: () => {
              form.clearErrors("data.email_verify");
            },
            validate: (value) =>
              value === form.getValues("data.email_verify")
                ? true
                : intl.formatMessage({
                    defaultMessage: "Les adresses email ne correspondent pas",
                  }),
          }}
          label={intl.formatMessage({ defaultMessage: "Email" })}
        />

        <FormControlRHF
          control={form.control}
          name="data.email_verify"
          type="email"
          rules={{
            required: true,
            onChange: () => {
              form.clearErrors("data.email");
            },
            validate: (value) =>
              value === form.getValues("data.email")
                ? true
                : intl.formatMessage({
                    defaultMessage: "Les adresses email ne correspondent pas",
                  }),
          }}
          label={intl.formatMessage({
            defaultMessage: "Répéter votre adresse email",
          })}
          placeholder={intl.formatMessage({
            defaultMessage: "Email",
          })}
        />
      </RGrid>

      <FormControlRHF
        control={form.control}
        name="data.message"
        type="textarea"
        rules={{ required: true, maxLength: 1000 }}
        label={intl.formatMessage({ defaultMessage: "Message" })}
        withCharactersCounter={true}
      />
    </Stack>
  );
}
