import {
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
  Wrap,
} from "@chakra-ui/react";
import { memo, useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Href } from "../Href";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} ShareProvider
 * @property {string} name
 * @property {string} icon
 * @property {(params: { title: string | undefined, text: string | undefined, url: string | undefined }) => string | undefined} generateUrl
 */

/** @type {ShareProvider[]} */
const SHARE_PROVIDERS = [
  {
    name: "Facebook",
    icon: "sf_facebook",
    generateUrl: (params) => {
      if (params.url === undefined) {
        return undefined;
      }
      return `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
        params.url,
      )}`;
    },
  },
  {
    name: "Email",
    icon: "ms_email",
    generateUrl: (params) => {
      const subject = params.title ? encodeURIComponent(params.title) : "";
      const body = `${
        params.text ? `${encodeURIComponent(`${params.text}\n\n`)}` : ""
      }${params.url ? encodeURIComponent(params.url) : ""}`;
      return `mailto:adresse@email.com?subject=${subject}&body=${body}`;
    },
  },
  {
    name: "WhatsApp",
    icon: "sf_whatsapp", // TODO fix icon
    generateUrl: (params) => {
      // si tous les paramètres sont undefined
      if (Object.values(params).every((value) => value === undefined)) {
        return undefined;
      }
      const text = `${
        params.title ? encodeURIComponent(`${params.title}\n\n`) : ""
      }${params.text ? encodeURIComponent(`${params.text}\n\n`) : ""}${
        params.url ? encodeURIComponent(params.url) : ""
      }`;
      return `https://wa.me/?text=${text}`;
    },
  },
];

export const Share = memo(
  /**
   * @typedef {object} Props
   * @property {string} [title]
   * @property {string} [text]
   * @property {string} [url]
   * @property {(params: { share: () => void }) => import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function Share({ title, text, url, children }) {
    const intl = useIntl();

    const { isOpen, onOpen, onClose } = useDisclosure();

    const share = useCallback(() => {
      if (!navigator.share) {
        onOpen();
        return;
      }
      const data = { title, text, url };
      if (!navigator.canShare(data)) {
        onOpen();
        return;
      }
      if (navigator.share) {
        navigator.share(data);
      }
    }, [onOpen, text, title, url]);

    const shareProviders = useMemo(() => {
      return SHARE_PROVIDERS.sort((a, b) => a.name.localeCompare(b.name));
    }, []);

    return (
      <>
        {children({ share })}

        <Modal
          isOpen={isOpen}
          onClose={onClose}
          isCentered={true}
          scrollBehavior="inside">
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>
              <HStack>
                <Text flexGrow={1}>
                  <FormattedMessage defaultMessage="Partager" />
                </Text>

                <IconButton
                  onClick={onClose}
                  icon={<Icon icon="ms_close" />}
                  size="sm"
                  variant="outline"
                  borderRadius="full"
                  aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
                />
              </HStack>
            </ModalHeader>

            <ModalBody pb="1rem">
              <Wrap>
                {shareProviders.map((shareProvider, index) => {
                  const href = shareProvider.generateUrl({ title, text, url });
                  if (!href) {
                    return null;
                  }
                  return (
                    <Href href={href} target="_blank" key={index}>
                      <Button
                        size="sm"
                        leftIcon={<Icon icon={shareProvider.icon} />}>
                        {shareProvider.name}
                      </Button>
                    </Href>
                  );
                })}
              </Wrap>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);
