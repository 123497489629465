import {
  AspectRatio,
  Box,
  Button,
  Collapse,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { memo } from "react";
import {
  HOTELS_MEDIA_TYPE_VALUE_VIDEO,
  HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT,
} from "@raiden/library-ui/constants/hotels";
import { FormattedMessage } from "react-intl";

export const MediaLoader = memo(
  /**
   * @typedef {object} Props
   * @property {keyof typeof import("@raiden/library-ui/constants/hotels").HOTELS_MEDIAS_TYPES} type
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function MediaLoader({ type, children }) {
    const { isOpen, onOpen } = useDisclosure();

    return (
      <Box>
        <Collapse in={!isOpen} unmountOnExit={true}>
          <AspectRatio ratio={16 / 9}>
            <VStack
              spacing="24px"
              justifyContent="center"
              backgroundColor="#eee"
              w="full"
              h="full"
              p="16px">
              <Button onClick={onOpen} colorScheme="brandPrimary">
                {(() => {
                  switch (type) {
                    case HOTELS_MEDIA_TYPE_VALUE_VIDEO:
                      return (
                        <FormattedMessage defaultMessage="Charger la vidéo" />
                      );
                    case HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT:
                      return (
                        <FormattedMessage defaultMessage="Charger la visite virtuelle" />
                      );
                    default:
                      return <FormattedMessage defaultMessage="Charger" />;
                  }
                })()}
              </Button>
            </VStack>
          </AspectRatio>
        </Collapse>

        <Collapse in={isOpen} unmountOnExit={true}>
          {children}
        </Collapse>
      </Box>
    );
  },
);
