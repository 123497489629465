import bookingsEstimateGetOwnerAcknowledgmentTimeout from "@raiden/library-ui/helpers/bookings/estimate/getOwnerAcknowledgmentTimeout";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  HStack,
  Box,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/CartBookingEstimation").BookingEstimation} bookingEstimation
 */

import { Text } from "@chakra-ui/react";
import { useIntl } from "react-intl";

/**
 * @param {Props} props
 */
export function CartBookingsEstimationOack({ bookingEstimation }) {
  const intl = useIntl();

  return (
    <HStack>
      <Text fontSize=".875rem" color="red.500" textAlign="center">
        {bookingEstimation?.owner_acknowledgment &&
          intl.formatMessage(
            {
              defaultMessage:
                "Confirmation de réservation sous {nbHour, plural, =0 {} other {#h}}",
            },
            {
              nbHour: bookingsEstimateGetOwnerAcknowledgmentTimeout({
                bookingEstimation,
              }),
            },
          )}

        {!bookingEstimation?.owner_acknowledgment &&
          intl.formatMessage({ defaultMessage: "Réservation immédiate" })}
      </Text>

      {bookingEstimation?.owner_acknowledgment && bookingEstimation?.on_demand && (
        <Popover>
          <PopoverTrigger>
            <Text as="button" type="button">
              <Icon icon="ms_error" size=".875rem" color="red.500" />
            </Text>
          </PopoverTrigger>

          <PopoverContent>
            <PopoverArrow />

            <PopoverCloseButton />

            <PopoverBody>
              <Box p=".875rem">
                <Text fontSize=".875rem">
                  {intl.formatMessage({
                    defaultMessage:
                      "Entre 21h et 8h, le délai d'acceptation est automatiquement prolongé. Celui ci ne pourra pas dépasser 24h au total.",
                  })}
                </Text>
              </Box>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      )}
    </HStack>
  );
}
