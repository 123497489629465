import { createContext, memo, useMemo } from "react";

export const RTdContext = createContext({
  columnIndex: -1,
});

export const RTdContextProvider = memo(
  /**
   * @typedef {object} Props
   * @property {number} columnIndex
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function RTdContextProvider({ columnIndex, children }) {
    const value = useMemo(() => {
      return {
        columnIndex,
      };
    }, [columnIndex]);

    return <RTdContext.Provider value={value}>{children}</RTdContext.Provider>;
  },
);
