import { defineMessage } from "react-intl";

export const SEASONS_VALUE_SUMMER = "summer";
export const SEASONS_VALUE_WINTER = "winter";
export const SEASONS_VALUE_SPRING = "spring";
export const SEASONS_VALUE_AUTUMN = "autumn";

/**
 * @type {Record<import("../types/Season").Season, { value: import("../types/Season").Season, label: import("react-intl").MessageDescriptor}>}
 */
export const SEASONS = {
  [SEASONS_VALUE_SPRING]: {
    value: SEASONS_VALUE_SPRING,
    label: defineMessage({
      defaultMessage: "Printemps",
    }),
  },
  [SEASONS_VALUE_SUMMER]: {
    value: SEASONS_VALUE_SUMMER,
    label: defineMessage({
      defaultMessage: "Été",
    }),
  },
  [SEASONS_VALUE_AUTUMN]: {
    value: SEASONS_VALUE_AUTUMN,
    label: defineMessage({
      defaultMessage: "Automne",
    }),
  },
  [SEASONS_VALUE_WINTER]: {
    value: SEASONS_VALUE_WINTER,
    label: defineMessage({
      defaultMessage: "Hiver",
    }),
  },
};

export const SEASONS_LIST = Object.values(SEASONS);

export const SEASONS_VALUES = SEASONS_LIST.map((season) => season.value);
