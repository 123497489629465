import { RGrid } from "@raiden/library-ui/components/RGrid";
import { memo, useMemo } from "react";
import { useIntl } from "react-intl";
import { HotelViewInfoItem } from "./InfoItem";

export const HotelViewHotelInfo = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   */
  /**
   * @param {Props} props
   */
  function HotelViewHotelInfo({ hotel }) {
    const intl = useIntl();

    const countBedrooms = useMemo(() => {
      return (
        hotel.rooms?.reduce((acc, room) => {
          return acc + (room.composition_infos?.count_bedrooms ?? 0);
        }, 0) ?? 0
      );
    }, [hotel.rooms]);

    const countBeds = useMemo(() => {
      return (
        hotel.rooms?.reduce((acc, room) => {
          return acc + (room.composition_infos?.count_beds ?? 0);
        }, 0) ?? 0
      );
    }, [hotel.rooms]);

    const countBathrooms = useMemo(() => {
      return (
        hotel.rooms?.reduce((acc, room) => {
          return acc + (room.composition_infos?.count_bathrooms ?? 0);
        }, 0) ?? 0
      );
    }, [hotel.rooms]);

    const petsInfo = useMemo(() => {
      if (!hotel.pets || !hotel.pets.allow_pets) {
        return undefined;
      }
      const {
        max_dog_weight: maxDogWeight,
        allow_dangerous_pets: allowDangerousPets,
        allow_nac: allowNac,
        max_pets: maxPets,
      } = hotel.pets;
      let dogsText;
      if (maxDogWeight) {
        const maxDogWeightText = intl.formatNumber(maxDogWeight, {
          style: "unit",
          unit: "kilogram",
        });
        if (allowDangerousPets) {
          dogsText = intl.formatMessage(
            {
              defaultMessage: "les chiens jusqu'à {maxDogWeight}",
            },
            { maxDogWeight: maxDogWeightText },
          );
        } else {
          dogsText = intl.formatMessage(
            {
              defaultMessage:
                "les chiens jusqu'à {maxDogWeight} (hormis les catégories 1 et 2)",
            },
            { maxDogWeight: maxDogWeightText },
          );
        }
      } else {
        if (allowDangerousPets) {
          dogsText = intl.formatMessage({
            defaultMessage: "les chiens",
          });
        } else {
          dogsText = intl.formatMessage({
            defaultMessage: "les chiens (hormis les catégories 1 et 2)",
          });
        }
      }

      const allowedAnimals = [
        dogsText,
        intl.formatMessage({ defaultMessage: "les chats" }),
      ];

      if (allowNac) {
        allowedAnimals.push(
          intl.formatMessage({
            defaultMessage: "les nouveaux animaux de compagnie",
          }),
        );
      }

      if (maxPets !== null && maxPets > 0) {
        return intl.formatMessage(
          {
            defaultMessage:
              "{allowedAnimals} sont acceptés jusqu'à {maxPets, plural, one {# animal} other {# animaux}} maximum.",
          },
          { allowedAnimals: intl.formatList(allowedAnimals), maxPets },
        );
      }

      return intl.formatMessage(
        { defaultMessage: "{allowedAnimals} sont acceptés." },
        { allowedAnimals: intl.formatList(allowedAnimals) },
      );
    }, [hotel.pets, intl]);

    return (
      <RGrid minCellWidth="100px">
        {hotel.legal_capacity > 0 && (
          <HotelViewInfoItem
            icon="ms_people"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# personne} other {# personnes}}",
              },
              {
                count: hotel.legal_capacity,
              },
            )}
          />
        )}

        {countBedrooms > 0 && (
          <HotelViewInfoItem
            icon="ms_door_front"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# chambre} other {# chambres}}",
              },
              {
                count: countBedrooms,
              },
            )}
          />
        )}

        {countBeds > 0 && (
          <HotelViewInfoItem
            icon="ms_bed"
            label={intl.formatMessage(
              {
                defaultMessage: "{count, plural, one {# lit} other {# lits}}",
              },
              {
                count: countBeds,
              },
            )}
          />
        )}

        {countBathrooms > 0 && (
          <HotelViewInfoItem
            icon="ms_bed"
            label={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, one {# salle d'eau} other {# salles d'eau}}",
              },
              {
                count: countBathrooms,
              },
            )}
          />
        )}

        <HotelViewInfoItem
          icon="ms_pets"
          label={
            hotel.pets?.allow_pets
              ? intl.formatMessage({ defaultMessage: "Animaux acceptés" })
              : intl.formatMessage({ defaultMessage: "Animaux non admis" })
          }
          info={petsInfo}
        />
      </RGrid>
    );
  },
);
