import { Box, HStack, IconButton, Stack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";

export const HotelViewAmenitiesTabs = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomAmenity[]} roomAmenities
   */
  /**
   * @param {Props} props
   */
  function HotelViewAmenitiesTabs({ roomAmenities }) {
    const intl = useIntl();

    const translate = useTranslate();

    const [amenityId, setAmenityId] = useState(roomAmenities[0]?.id);

    useEffect(() => {
      setAmenityId(roomAmenities[0]?.id);
    }, [roomAmenities]);

    const handleClick = useCallback((event) => {
      const id = Number(event.currentTarget.dataset.id);
      setAmenityId(id);
    }, []);

    return (
      <Box px="1rem">
        <HStack spacing="0" borderTopRadius="10px" flexWrap="wrap">
          {roomAmenities.map((amenity) => (
            <IconButton
              onClick={handleClick}
              data-id={JSON.stringify(amenity.id)}
              icon={<Icon icon={amenity.icon ?? "ms_star"} size="48px" />}
              variant="unstyled"
              borderBottomWidth="2px"
              borderBottomColor={
                amenityId === amenity.id ? "gray.200" : "transparent"
              }
              borderRadius="0"
              size="lg"
              aria-label={
                translate(amenity.name) ??
                intl.formatMessage({ defaultMessage: "Ouvrir" })
              }
              transition="none"
              key={amenity.id}
            />
          ))}
        </HStack>

        {roomAmenities.map((roomAmenity) => {
          const name = translate(roomAmenity.name);
          const about = translate(roomAmenity.about);
          return (
            <Stack
              spacing="4px"
              display={amenityId === roomAmenity.id ? "flex" : "none"}
              backgroundColor="gray.100"
              borderTopLeftRadius={
                amenityId !== roomAmenities[0].id ? "10px" : "0"
              }
              borderTopRightRadius="10px"
              borderBottomRadius="10px"
              py="12px"
              px="16px"
              key={roomAmenity.id}>
              {name && (
                <Text variant="text-sm" color="gray.700">
                  {name}
                </Text>
              )}

              {about && (
                <Text variant="text-sm" color="gray.500">
                  {about}
                </Text>
              )}
            </Stack>
          );
        })}
      </Box>
    );
  },
);
