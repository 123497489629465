import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Wrap,
} from "@chakra-ui/react";
import generateBookingUrl from "@raiden/library-ui/libraries/utils/generateBookingUrl";
import { useRef } from "react";
import { FormattedMessage } from "react-intl";
import { Href } from "../../../components/Href";
import { useCart } from "../../../hooks/useCart";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function ModalBookingSuccess({ isOpen, onClose }) {
  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const { cart } = useCart();

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent mx="1rem">
        <ModalHeader>
          <FormattedMessage defaultMessage="Le séjour a été ajouté à votre panier" />
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormattedMessage defaultMessage="Vous pouvez compléter votre voyage ou voir votre panier." />
        </ModalBody>

        <ModalFooter>
          <Wrap>
            <Button onClick={onClose} colorScheme="gray">
              <FormattedMessage defaultMessage="Compléter mon voyage" />
            </Button>

            <Href
              href={
                cart
                  ? generateBookingUrl({
                      id: "@booking.cart-update",
                      query: {
                        cartId: cart.id,
                        cartToken: cart.access_token,
                      },
                    })
                  : undefined
              }>
              <Button ref={ref} colorScheme="brandSecondary">
                <FormattedMessage defaultMessage="Voir mon panier" />
              </Button>
            </Href>
          </Wrap>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
