import { useApiError } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import encodeFormData from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeFormData";
import { useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useCart } from "../../../hooks/useCart";

/**
 * @typedef {object} CartBookingsCreateService
 * @property {number} service_id
 * @property {number} quantity
 */

/**
 * @typedef {object} CartBookingsCreateFormValues
 * @property {number} room_id
 * @property {string} checkin
 * @property {string} checkout
 * @property {number} adults
 * @property {number} children
 * @property {number} babies
 * @property {number} pets
 * @property {boolean} travel_for_work
 * @property {CartBookingsCreateService[]} services
 */

/**
 * @param {object} params
 * @param {number} params.roomId
 * @param {string} [params.checkin]
 * @param {string} [params.checkout]
 * @param {number} [params.adults]
 * @param {number} [params.children]
 * @param {number} [params.babies]
 * @param {number} [params.pets]
 */
export function getCartBookingsCreateFormValues({
  roomId,
  checkin,
  checkout,
  adults,
  children,
  babies,
  pets,
}) {
  /** @type {CartBookingsCreateFormValues} */
  const defaultValues = {
    room_id: roomId,
    checkin: checkin ?? "",
    checkout: checkout ?? "",
    adults: adults ?? 1,
    children: children ?? 0,
    babies: babies ?? 0,
    pets: pets ?? 0,
    travel_for_work: false,
    services: [],
  };
  return defaultValues;
}

/**
 * @callback CartBookingsCreateHandlerOnCartCreated
 * @param {{ response: import("@raiden/library-ui/types/Api/ApiResponse").ApiResponse }} params
 */

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 * @property {number} initialRoomId
 * @property {string} [initialCheckin]
 * @property {string} [initialCheckout]
 * @property {number} [initialAdults]
 * @property {number} [initialChildren]
 * @property {number} [initialBabies]
 * @property {number} [initialPets]
 * @property {CartBookingsCreateHandlerOnCartCreated} [onCartCreated] triggers when the cart is created
 * @property {() => void} [onBookingCreated] triggers when the booking is added to the cart
 * @property {() => void} [onSuccess] triggers when the cart or the booking is created
 * @property {(params: {form: import("react-hook-form").UseFormReturn<CartBookingsCreateFormValues>, submit: () => void, isLoading: boolean}) => import("react").ReactElement} children
 */
/**
 * @param {Props} props
 */
export function CartBookingsCreateHandler({
  initialRoomId,
  initialCheckin,
  initialCheckout,
  initialAdults,
  initialChildren,
  initialBabies,
  initialPets,
  onCartCreated,
  onBookingCreated,
  onSuccess,
  children,
}) {
  const { cartInfo } = useCart();

  const form = useForm({
    defaultValues: getCartBookingsCreateFormValues({
      roomId: initialRoomId,
      checkin: initialCheckin,
      checkout: initialCheckout,
      adults: initialAdults,
      children: initialChildren,
      babies: initialBabies,
      pets: initialPets,
    }),
  });
  const { handleSubmit } = form;

  const { request, isLoading } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const submit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit((values) => {
        const bookingPayload = {
          ...values,
          source: "web",
          nature: values.travel_for_work ? "pro" : "par",
          travel_for_work: undefined,
          services: values.services.length > 0 ? values.services : undefined,
        };
        if (cartInfo !== undefined) {
          request(
            generateApiUrl({
              id: "@cartsBookings.create",
              parameters: {
                cartId: cartInfo.id,
              },
              query: {
                cart_token: cartInfo.accessToken,
              },
            }),
            {
              method: "POST",
              body: encodeFormData({
                data: bookingPayload,
              }),
            },
            {
              inputMode: "formData",
            },
          )
            .then((response) => {
              resetApiErrors();
              onBookingCreated?.();
              onSuccess?.();
            })
            .catch((response) => {
              resetApiErrors();
              resolveApiError({ response, form });
            });
        } else {
          request(
            generateApiUrl({
              id: "@carts.create",
            }),
            {
              method: "POST",
              body: encodeFormData({
                data: {
                  workflow: "system",
                  bookings: [bookingPayload],
                },
              }),
            },
            {
              inputMode: "formData",
            },
          )
            .then((response) => {
              resetApiErrors();
              onCartCreated?.({ response });
              onSuccess?.();
            })
            .catch((response) => {
              resetApiErrors();
              resolveApiError({ response, form });
            });
        }
      })();
    },
    [
      cartInfo,
      form,
      handleSubmit,
      onBookingCreated,
      onCartCreated,
      onSuccess,
      request,
      resetApiErrors,
      resolveApiError,
    ],
  );

  return (
    <FormProvider {...form}>
      {children({ form, submit, isLoading })}
    </FormProvider>
  );
}
