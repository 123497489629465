/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/RoomService").RoomService[]} params.roomServices
 * @returns {import("@raiden/library-ui/types/RoomService").RoomService[]}
 */
export function sortRoomServices({ roomServices }) {
  const copy = [...roomServices];
  return copy.sort((a, b) => {
    if (a.is_required && !b.is_required) {
      return -1;
    }
    if (!a.is_required && b.is_required) {
      return 1;
    }
    return 0;
  });
}
