import { Stack } from "@chakra-ui/react";
import uniqid from "uniqid";
import { Select } from "@raiden/library-ui/components/Form/Select/Select";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import Script from "next/script";
import { useCallback, useMemo, useState } from "react";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 * @property {string} iteaApiKey
 * @property {string} [checkin]
 * @property {string} [checkout]
 */
/**
 * @param {Props} props
 */
export function WidgetITEA({ hotel, iteaApiKey, checkin, checkout }) {
  const translate = useTranslate();

  const [hlo, setHlo] = useState(hotel.rooms?.[0].hlo ?? "");

  const hloOptions = useMemo(() => {
    /** @type {import("@raiden/library-ui/components/Form/Select/Select").SelectOption<string, undefined>[]}*/
    const options =
      hotel.rooms?.map((room) => ({
        value: room.hlo,
        label: translate(room.name) ?? String(room.id),
        data: undefined,
      })) ?? [];
    return options;
  }, [hotel.rooms, translate]);

  const handleChange = useCallback(
    /** @type {(event: { target: { name: string, value: string } }) => void} */
    (event) => {
      setHlo(event.target.value);
    },
    [],
  );

  return (
    <Stack spacing="12px">
      {hloOptions.length > 1 && (
        <Select
          variant="outline"
          value={hlo}
          onChange={handleChange}
          options={hloOptions}
          isInvalid={false}
          isRequired={false}
          w="full"
        />
      )}

      <WidgetIteaForm
        hlo={hlo}
        iteaApiKey={iteaApiKey}
        checkin={checkin}
        checkout={checkout}
        key={hlo}
      />
    </Stack>
  );
}

/**
 * @typedef {object} WidgetProps
 * @property {string} hlo
 * @property {string} iteaApiKey
 * @property {string} [checkin]
 * @property {string} [checkout]
 */
/**
 * Ce composant permet de regénérer automatiquement le script en ajoutant un uid dans l'url afin de forcer next à recharger le script
 * @param {WidgetProps} props
 */
function WidgetIteaForm({ hlo, iteaApiKey, checkin, checkout }) {
  const uid = useMemo(() => {
    return uniqid();
  }, []);

  return (
    <Stack spacing="12px">
      <Script
        type="text/javascript"
        src={`https://widget.itea.fr/js/itea_widget.v2.js?uid=${uid}`}
      />

      <div
        id="widget_resacleva"
        data-widget-itea="resacleva"
        data-key={iteaApiKey}
        data-numgite={hlo}
        data-deb={checkin}
        data-fin={checkout}
      />
    </Stack>
  );
}
