import { Box, Button, LightMode, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";

import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { Href } from "../../../components/Href";
import { useConfiguration } from "@raiden/library-ui/hooks/useConfiguration";

import useTranslate from "@raiden/library-ui/hooks/useTranslate";
export function Insurance() {
  const { configuration } = useConfiguration();
  const pageInsuranceAlias = process.env.NEXT_PUBLIC_POST_PAGE_INSURANCE_ALIAS;

  const postInsurance = configuration.posts?.page?.find((post) => {
    return pageInsuranceAlias !== undefined
      ? post.alias === pageInsuranceAlias
      : undefined;
  });

  const translate = useTranslate();
  if (!postInsurance) {
    return null;
  }

  return (
    <>
      <Text
        colorScheme="brandPrimary"
        as="span"
        variant="text-sm"
        mb="8px"
        textAlign="center">
        <FormattedMessage defaultMessage="Ne perdez plus d'argent sur vos acomptes ! Pensez à l'assurance annulation." />
      </Text>

      <Box textAlign="center">
        <LightMode>
          <Href
            href={generateFrontPath({
              id: "@front.page.view",
              parameters: {
                postSlug: `${postInsurance.id}-${translate(
                  postInsurance.slug,
                )}`,
              },
            })}>
            <Button variant="outline">
              <Text variant="text-sm">
                <FormattedMessage defaultMessage="Plus d'informations" />
              </Text>
            </Button>
          </Href>
        </LightMode>
      </Box>
    </>
  );
}
