import { Box } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function Card({ children }) {
  return (
    <Box
      backgroundColor="#fff"
      borderRadius="20px"
      boxShadow="0px 1px 0px 0px rgba(24, 40, 49, 0.25)"
      maxW="100%">
      {children}
    </Box>
  );
}
