import { HStack, Text } from "@chakra-ui/react";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo } from "react";
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer";
import { Icon } from "@raiden/library-ui/components/Icon";

export const HotelViewAmenities = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomAmenity[]} roomAmenities
   */
  /**
   * @param {Props} props
   */
  function HotelViewAmenities({ roomAmenities }) {
    const translate = useTranslate();

    return (
      <ExpandableContainer maxHeight={40 * 3}>
        <RGrid gridRowGap={0} gridColumnGap="24px" minCellWidth="190px">
          {roomAmenities.map((roomAmenity) => (
            <HStack pr="12px" py="8px" key={roomAmenity.id}>
              <Icon icon={roomAmenity.icon ?? "ms_star"} />

              <Text as="span" variant="text-label">
                {translate(roomAmenity.name)}
              </Text>
            </HStack>
          ))}
        </RGrid>
      </ExpandableContainer>
    );
  },
);
