import { HStack, Text, useDisclosure } from "@chakra-ui/react";
import { ModalWidgetSncf } from "./ModalWidgetSncf";
import { Icon } from "@raiden/library-ui/components/Icon";
import { FormattedMessage } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 */
/**
 * @param {Props} props
 */
export function Sncf({ form, hotel }) {
  const disclosureModalWidgetSncf = useDisclosure();

  return (
    <>
      <ModalWidgetSncf
        isOpen={disclosureModalWidgetSncf.isOpen}
        onClose={disclosureModalWidgetSncf.onClose}
        form={form}
        hotel={hotel}
      />

      <HStack
        as="button"
        type="button"
        onClick={disclosureModalWidgetSncf.onOpen}>
        <Icon icon="ms_train" size="1.5rem" />

        <Text
          textDecoration="underline"
          as="span"
          color="inherit"
          variant="text-sm-link">
          <FormattedMessage defaultMessage="Pour vous y rendre, pensez au train !" />
        </Text>
      </HStack>
    </>
  );
}
