import {
  Button,
  Checkbox,
  Collapse,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { CheckinCheckoutInput } from "@raiden/library-ui/components/Form/CheckinCheckoutInput";
import { GuestsInput } from "@raiden/library-ui/components/Form/GuestsInput";
import { Select } from "@raiden/library-ui/components/Form/Select/Select";
import { Icon } from "@raiden/library-ui/components/Icon";
import FormControlRHF from "@raiden/library-ui/components/ReactHookForm/FormControlRHF";
import FormErrorMessageRHF from "@raiden/library-ui/components/ReactHookForm/FormErrorMessageRHF";
import FormErrorObserverRHF from "@raiden/library-ui/components/ReactHookForm/FormErrorObserverRHF";
import FormObserver from "@raiden/library-ui/components/ReactHookForm/FormObserver";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("./Handler").CartBookingsCreateFormValues>} form
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 */
/**
 * @param {Props} props
 */
export function CartBookingsCreateForm({ form, hotel }) {
  const translate = useTranslate();

  const {
    isOpen: isOpenGuests,
    onOpen: onOpenGuests,
    onClose: onCloseGuests,
  } = useDisclosure();

  const roomsOptions = useMemo(() => {
    /** @type {import("@raiden/library-ui/components/Form/Select/Select").SelectOption<number, undefined>[]}*/
    const options =
      hotel.rooms?.map((room) => ({
        value: room.id,
        label: translate(room.name) ?? String(room.id),
        data: undefined,
      })) ?? [];
    return options;
  }, [hotel.rooms, translate]);

  const handleChangeCheckinCheckout = useCallback(
    ({ checkin, checkout }) => {
      form.setValue("checkin", checkin, { shouldDirty: true });
      form.setValue("checkout", checkout, { shouldDirty: true });
    },
    [form],
  );

  const renderTrigger = useCallback(
    /** @type {import("@raiden/library-ui/components/Form/GuestsInput").GuestsInputRenderTriggerCallback} */
    ({ label }) => (
      <Button
        variant="outline"
        leftIcon={<Icon icon="ms_people" size="24px" color="gray.500" />}
        minW="10.3125rem"
        h="44px"
        justifyContent="flex-start">
        <Text as="span" variant="text-input">
          {label}
        </Text>
      </Button>
    ),
    [],
  );

  return (
    <Stack spacing="12px">
      {roomsOptions.length > 1 && (
        <FormControlRHF
          control={form.control}
          name="room_id"
          rules={{ required: true }}
          render={(field) => (
            <Select
              {...field}
              variant="outline"
              options={roomsOptions}
              w="full"
            />
          )}
        />
      )}

      <FormObserver control={form.control} name="room_id">
        {(roomId) => (
          <FormObserver control={form.control} name="checkin">
            {(checkin) => (
              <FormObserver control={form.control} name="checkout">
                {(checkout) => (
                  <>
                    <CheckinCheckoutInput
                      bookingId={null}
                      roomId={roomId}
                      capacity={1}
                      checkin={checkin}
                      checkout={checkout}
                      includeOnPast={false}
                      includeRestricted={false}
                      includeWithoutPrices={true}
                      onChange={handleChangeCheckinCheckout}
                    />

                    {["checkin", "checkout"].map((name) => (
                      <FormErrorObserverRHF
                        key={name}
                        name={name}
                        render={({ hasError, error }) => (
                          <Collapse in={hasError} unmountOnExit={true}>
                            <FormErrorMessageRHF error={error} />
                          </Collapse>
                        )}
                      />
                    ))}
                  </>
                )}
              </FormObserver>
            )}
          </FormObserver>
        )}
      </FormObserver>

      <FormObserver control={form.control} name="room_id">
        {(roomId) => (
          <GuestsInput
            isOpen={isOpenGuests}
            onOpen={onOpenGuests}
            onClose={onCloseGuests}
            renderTrigger={renderTrigger}
            maxCapacity={
              hotel.rooms?.find((room) => room.id === roomId)?.capacity
            }
          />
        )}
      </FormObserver>

      <FormControlRHF
        control={form.control}
        name="travel_for_work"
        render={(field) => (
          <Checkbox
            {...{ ...field, value: undefined }}
            isChecked={field.value === true}
            colorScheme="brandPrimary">
            <FormattedMessage defaultMessage="Je voyage pour le travail" />
          </Checkbox>
        )}
      />
    </Stack>
  );
}
