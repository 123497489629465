import { RGrid } from "@raiden/library-ui/components/RGrid";
import { memo } from "react";
import { HotelViewRoomCompositionCard } from "./RoomCompositionCard";

export const HotelViewRoomCompositions = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomComposition[]} roomCompositions
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomCompositions({ roomCompositions }) {
    return (
      <RGrid gridGap="14px" minCellWidth="305px">
        {roomCompositions.map((roomComposition) => (
          <HotelViewRoomCompositionCard
            roomComposition={roomComposition}
            key={roomComposition.id}
          />
        ))}
      </RGrid>
    );
  },
);
