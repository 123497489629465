import { Box, useColorModeValue } from "@chakra-ui/react";
import { memo, useContext } from "react";
import { RTableContext } from "./RTableContext";
import { RTdContext } from "./RTdContext";
import { RTrContext } from "./RTrContext";

export const RTd = memo(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactNode} [children]
   */
  /**
   * @param {Props} props
   */
  function RTd({ children }) {
    const {
      columns,
      responsiveBreakpoint,
      isStriped,
      useDesktopDivider,
      cellPadding,
    } = useContext(RTableContext);

    const { isFirstRow, isLastRow } = useContext(RTrContext);

    const { columnIndex } = useContext(RTdContext);

    const color = useColorModeValue("gray.700", "gray.300");

    return (
      <Box
        verticalAlign="middle"
        display="table-cell"
        h="auto"
        px={cellPadding}
        pt={cellPadding}
        pb={isStriped || useDesktopDivider ? cellPadding : undefined}
        borderBottomWidth={useDesktopDivider && !isLastRow ? "1px" : undefined}
        sx={{
          "&:first-of-type": {
            pl: isStriped ? `calc(${cellPadding} * 2)` : undefined,
          },
          "&:last-of-type": {
            pr: isStriped ? `calc(${cellPadding} * 2)` : undefined,
          },
          [`@container (max-width: ${responsiveBreakpoint})`]: {
            "&:first-of-type": {
              pt: isStriped
                ? `calc(${cellPadding} * 2)`
                : isFirstRow
                ? 0
                : `calc(${cellPadding} * 2)`,
            },
            "&:last-of-type": {
              pb: isStriped
                ? `calc(${cellPadding} * 2)`
                : isLastRow
                ? 0
                : `calc(${cellPadding} * 2)`,
            },
            "&": {
              display: "block",
              px: isStriped ? `calc(${cellPadding} * 2)` : 0,
              borderBottomWidth: "0px",
            },
          },
        }}
        _first={{
          pl: 0,
        }}
        _last={{
          pr: 0,
        }}>
        {columns?.[columnIndex]?.title && (
          <Box
            display="none"
            color={color}
            fontWeight="bold"
            sx={{
              [`@container (max-width: ${responsiveBreakpoint})`]: {
                "&": {
                  display: "block",
                },
              },
            }}>
            {columns[columnIndex].title}
          </Box>
        )}

        <Box
          textAlign={columns[columnIndex]?.textAlign}
          sx={{
            [`@container (max-width: ${responsiveBreakpoint})`]: {
              "&": {
                textAlign: "left",
              },
            },
          }}>
          {children}
        </Box>
      </Box>
    );
  },
);
