import {
  Box,
  Button,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  Text,
  useBreakpointValue,
  Wrap,
} from "@chakra-ui/react";
import {
  AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
  AMENITIES_CATEGORY_VALUE_INSIDE,
  AMENITIES_CATEGORY_VALUE_OUTSIDE,
} from "@raiden/library-ui/constants/amenities";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useMemo, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LabelRanking } from "../../../components/Common/LabelRanking";
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer";
import { Icon } from "@raiden/library-ui/components/Icon";
import { getRoomPrice } from "../../../helpers/rooms/getRoomPrice";
import { HotelViewAmenities } from "./RoomAmenities";
import { HotelViewPictures } from "./Pictures";
import { HotelViewRoomCompositions } from "./RoomCompositions";
import { HotelViewRoomInfo } from "./RoomInfo";
import { HotelViewRoomPrice } from "./RoomPrice";
import { HotelViewSection } from "./Section";
import { HotelViewRoomServices } from "./RoomServices";
import { HotelViewTopAmenity } from "./TopAmenity";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Room").Room} room
 * @property {"night" | "week"} durationUnit
 * @property {boolean} isOpen
 * @property {() => void} onClose
 * @property {() => void} handleBookClick
 * @property {import("@raiden/library-ui/types/Hotel").HotelType} hotelType
 */
/**
 * @param {Props} props
 */
export function HotelViewRoomModal({
  room,
  durationUnit,
  isOpen,
  onClose,
  handleBookClick,
  hotelType,
}) {
  const intl = useIntl();

  const translate = useTranslate();

  const isMobile = useBreakpointValue({ base: true, md: false });

  const roomPrice = useMemo(() => {
    return getRoomPrice({ room, durationUnit });
  }, [durationUnit, room]);

  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const indoorAmenities = useMemo(() => {
    return (
      room.amenities?.filter(
        (amenity) => amenity.category === AMENITIES_CATEGORY_VALUE_INSIDE,
      ) ?? []
    );
  }, [room.amenities]);

  const outdoorAmenities = useMemo(() => {
    return (
      room.amenities?.filter(
        (amenity) => amenity.category === AMENITIES_CATEGORY_VALUE_OUTSIDE,
      ) ?? []
    );
  }, [room.amenities]);

  const accessibilityAmenities = useMemo(() => {
    return (
      room.amenities?.filter(
        (amenity) =>
          amenity.category === AMENITIES_CATEGORY_VALUE_ACCESSIBILITY,
      ) ?? []
    );
  }, [room.amenities]);

  const bookButtonLabel = useMemo(() => {
    if (isMobile) {
      return intl.formatMessage({ defaultMessage: "Réserver" });
    }

    return intl.formatMessage(
      {
        defaultMessage:
          "{hotelType, select, guest_house {Sélectionner la chambre} other {Sélectionner le gîte}}",
      },
      {
        hotelType,
      },
    );
  }, [hotelType, intl, isMobile]);

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="4xl">
      <ModalOverlay />

      <ModalContent mx="16px">
        <ModalHeader borderBottomWidth="1px">
          <HStack justify="center" spacing="16px">
            <Flex justify="center" ml="48px" flexGrow={1}>
              {room.name ? (
                <Wrap spacing="12px">
                  <Text variant="h4" textAlign="center">
                    {translate(room.name)}
                  </Text>
                </Wrap>
              ) : (
                <FormattedMessage defaultMessage="Chambre" />
              )}
            </Flex>

            <IconButton
              onClick={onClose}
              icon={<Icon icon="ms_close" />}
              aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
              variant="outline"
              size="sm"
              borderRadius="full"
            />
          </HStack>
        </ModalHeader>

        <ModalBody p="0">
          <Stack spacing="16px" py="16px">
            <Box px="16px">
              <HotelViewPictures roomPictures={room.pictures} />
            </Box>

            <HStack px="16px">
              {room.surface && (
                <Text color="gray.500">
                  <FormattedMessage
                    defaultMessage="{surface} m²"
                    values={{
                      surface: room.surface,
                    }}
                  />
                </Text>
              )}

              {room.last_label_ranking &&
                room.last_label_ranking.value !== null && (
                  <Box>
                    <LabelRanking
                      labelRanking={room.last_label_ranking.value}
                    />
                  </Box>
                )}
            </HStack>

            {room.top_amenities && room.top_amenities.length > 0 && (
              <Wrap spacing="16px" px="16px">
                {room.top_amenities.map((amenity) => (
                  <HotelViewTopAmenity roomAmenity={amenity} key={amenity.id} />
                ))}
              </Wrap>
            )}

            <Box px="16px">
              <HotelViewRoomInfo
                room={room}
                withWCs={true}
                withBabyFriendly={true}
              />
            </Box>

            {room.about && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({
                    defaultMessage: "Description",
                  })}>
                  <ExpandableContainer
                    maxHeight={24 * 4}
                    withBottomNavBarMargin={false}>
                    <Text>{translate(room.about)}</Text>
                  </ExpandableContainer>
                </HotelViewSection>
              </Box>
            )}

            {room.compositions && room.compositions.length > 0 && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({ defaultMessage: "Les pièces" })}>
                  <HotelViewRoomCompositions
                    roomCompositions={room.compositions}
                  />
                </HotelViewSection>
              </Box>
            )}

            {indoorAmenities.length > 0 && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({
                    defaultMessage: "Équipements intérieurs",
                  })}>
                  <HotelViewAmenities roomAmenities={indoorAmenities} />
                </HotelViewSection>
              </Box>
            )}

            {outdoorAmenities.length > 0 && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({
                    defaultMessage: "Équipements extérieurs",
                  })}>
                  <HotelViewAmenities roomAmenities={outdoorAmenities} />
                </HotelViewSection>
              </Box>
            )}

            {accessibilityAmenities.length > 0 && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({
                    defaultMessage: "Accessibilité",
                  })}>
                  <HotelViewAmenities roomAmenities={accessibilityAmenities} />
                </HotelViewSection>
              </Box>
            )}

            {room.services && room.services.length > 0 && (
              <Box px="16px">
                <HotelViewSection
                  title={intl.formatMessage({
                    defaultMessage: "Suppléments",
                  })}>
                  <HotelViewRoomServices roomServices={room.services} />
                </HotelViewSection>
              </Box>
            )}
          </Stack>
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Wrap justify="flex-start" align="center" w="full">
            {roomPrice && (
              <Box>
                <HotelViewRoomPrice
                  price={roomPrice.price}
                  durationUnit={roomPrice.durationUnit}
                />
              </Box>
            )}

            <Flex flexGrow={1} justify="flex-end">
              <Button
                ref={ref}
                colorScheme="brandSecondary"
                onClick={handleBookClick}>
                {bookButtonLabel}
              </Button>
            </Flex>
          </Wrap>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
