import { Flex, Stack, Tag, Text, Wrap } from "@chakra-ui/react";
import { RGrid } from "@raiden/library-ui/components/RGrid";
import { roomsServicesStrategyMessage } from "@raiden/library-ui/constants/roomsServices";
import formatAmountByAmountMode from "@raiden/library-ui/helpers/rooms/services/formatAmountByAmountMode";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { sortRoomServices } from "../../../helpers/roomServices/sortRoomServices";

export const HotelViewRoomServices = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/RoomService").RoomService[]} roomServices
   */
  /**
   * @param {Props} props
   */
  function HotelViewServices({ roomServices }) {
    const intl = useIntl();

    const sortedServices = useMemo(() => {
      return sortRoomServices({ roomServices });
    }, [roomServices]);

    return (
      <RGrid minCellWidth="250px">
        {sortedServices.map((service, index) => (
          <Stack
            spacing="32px"
            backgroundColor="gray.100"
            borderRadius="10px"
            justify="space-between"
            p="12px"
            key={index}>
            <Stack spacing="6px">
              <Wrap justify="space-between" w="full">
                <Text variant="button-md">{service.name}</Text>

                {service.is_required && (
                  <Tag size="sm" colorScheme="brandPrimary">
                    <FormattedMessage defaultMessage="Obligatoire" />
                  </Tag>
                )}
              </Wrap>

              {service.about && <Text variant="text-xs">{service.about}</Text>}
            </Stack>

            <Flex justify="flex-end">
              {service.is_charge ? (
                <Text>
                  <FormattedMessage defaultMessage="Facturation en fin de séjour" />
                </Text>
              ) : (
                <Text>
                  <FormattedMessage
                    defaultMessage="{price} <lightGray>/ {strategy}</lightGray>"
                    values={{
                      price: formatAmountByAmountMode({
                        service,
                        intl,
                      }),
                      strategy: intl.formatMessage(
                        roomsServicesStrategyMessage,
                        {
                          strategy: service.strategy,
                        },
                      ),
                      lightGray: (chunks) => (
                        <Text
                          as="span"
                          color="gray.500"
                          sx={{ display: "inline" }}>
                          {chunks}
                        </Text>
                      ),
                    }}
                  />
                </Text>
              )}
            </Flex>
          </Stack>
        ))}
      </RGrid>
    );
  },
);
