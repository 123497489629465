import { Box, Stack, Text } from "@chakra-ui/react";

/**
 * @typedef {object} Props
 * @property {string} [title]
 * @property {React.ReactNode} children
 */
/**
 * @param {Props} props
 */
export function HotelViewSection({ title, children }) {
  return (
    <Stack spacing="16px">
      {title && <Text variant="h3">{title}</Text>}

      <Box>{children}</Box>
    </Stack>
  );
}
