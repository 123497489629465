import {
  Box,
  Button,
  Flex,
  HStack,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { getFloorMessage } from "@raiden/library-ui/helpers/rooms/getFloorMessage/getFloorMessage";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { HotelViewRoomCompositionModal } from "./RoomCompositionModal";

export const HotelViewRoomCompositionCard = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").RoomComposition} roomComposition
   */
  /**
   * @param {Props} props
   */
  function HotelViewRoomCompositionCard({ roomComposition }) {
    const intl = useIntl();

    const translate = useTranslate();

    const {
      isOpen: isOpenModal,
      onOpen: onOpenModal,
      onClose: onCloseModal,
    } = useDisclosure();

    return (
      <>
        <Stack
          onClick={onOpenModal}
          cursor="pointer"
          textAlign="left"
          backgroundColor="gray.100"
          borderRadius="10px"
          py="16px"
          px="20px"
          key={roomComposition.id}>
          <Text variant="text-input">{translate(roomComposition.name)}</Text>

          {(roomComposition.floor !== null ||
            roomComposition.surface !== null) && (
            <HStack>
              <Box flexGrow={1}>
                {roomComposition.floor !== null && (
                  <Text variant="text-sm" color="gray.500">
                    {intl.formatMessage(
                      getFloorMessage({ floor: roomComposition.floor }),
                      { floor: roomComposition.floor },
                    )}
                  </Text>
                )}
              </Box>

              {roomComposition.surface !== null && (
                <Text variant="text-sm" color="gray.500">
                  <FormattedMessage
                    defaultMessage="{surface} m²"
                    values={{
                      surface: roomComposition.surface,
                    }}
                  />
                </Text>
              )}
            </HStack>
          )}

          {roomComposition.about !== null && (
            <Text variant="text-xs">{translate(roomComposition.about)}</Text>
          )}

          <Flex justify="flex-end">
            <Button onClick={onOpenModal} variant="link">
              <Text colorScheme="brandPrimary" variant="text-sm">
                <FormattedMessage defaultMessage="Plus d'informations" />
              </Text>
            </Button>
          </Flex>
        </Stack>

        <HotelViewRoomCompositionModal
          roomComposition={roomComposition}
          isOpen={isOpenModal}
          onClose={onCloseModal}
        />
      </>
    );
  },
);
