import { Text, Wrap } from "@chakra-ui/react";
import { memo } from "react";
import { FormattedMessage } from "react-intl";

export const HotelViewQuickSummary = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   */
  /**
   * @param {Props} props
   */
  function HotelViewQuickSummary({ hotel }) {
    const countBedrooms = hotel.rooms?.reduce(
      (acc, room) => acc + (room.composition_infos?.count_bedrooms ?? 0),
      0,
    );

    const countBeds = hotel.rooms?.reduce(
      (acc, room) => acc + (room.composition_infos?.count_beds ?? 0),
      0,
    );

    return (
      <Wrap spacing="12px">
        {hotel.legal_capacity > 0 && (
          <Text color="gray.500">
            <FormattedMessage
              defaultMessage={
                "{countCapacity, plural, one {# personne} other {# personnes}}"
              }
              values={{ countCapacity: hotel.legal_capacity }}
            />
          </Text>
        )}

        {countBedrooms !== undefined && countBedrooms > 0 && (
          <Text color="gray.500">
            <FormattedMessage
              defaultMessage={
                "{count, plural, zero {# chambre} one {# chambre} other {# chambres}}"
              }
              values={{
                count: countBedrooms,
              }}
            />
          </Text>
        )}

        {countBeds !== undefined && countBeds > 0 && (
          <Text color="gray.500">
            <FormattedMessage
              defaultMessage={"{count, plural, one {# lit} other {# lits}}"}
              values={{
                count: countBeds,
              }}
            />
          </Text>
        )}
      </Wrap>
    );
  },
);
