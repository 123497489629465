import {
  Box,
  Flex,
  Grid,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Stack,
  Text,
} from "@chakra-ui/react";
import {
  SEASONS,
  SEASONS_LIST,
  SEASONS_VALUES,
} from "@raiden/library-ui/constants/seasons";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { RoomPictureLazy } from "../../SearchHotels/RoomPictureLazy/RoomPictureLazy";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} roomPictures
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function RoomPicturesModal({ roomPictures, isOpen, onClose }) {
  const intl = useIntl();

  const [selectedSeason, setSelectedSeason] = useState(
    /** @type {import("@raiden/library-ui/types/Season").Season | ""} */ (""),
  );

  const translate = useTranslate();

  const sortedRoomPictures = useMemo(() => {
    const sortedRoomPictures = [...roomPictures];
    sortedRoomPictures.sort((a, b) => {
      // Sort by room_id (null first)
      if (a.room_id === null && b.room_id !== null) {
        return -1;
      }
      if (a.room_id !== null && b.room_id === null) {
        return 1;
      }
      // Sort by season
      if (a.season === selectedSeason && b.season !== selectedSeason) {
        return -1;
      }
      if (a.season !== selectedSeason && b.season === selectedSeason) {
        return 1;
      }
      // Sort by position
      if (a.position < b.position) {
        return -1;
      }
      if (a.position > b.position) {
        return 1;
      }
      return 0;
    });
    return sortedRoomPictures;
  }, [roomPictures, selectedSeason]);

  const handleChange = useCallback(
    /** @type {import("react").ChangeEventHandler<HTMLSelectElement>} */
    (event) => {
      const newValue =
        SEASONS_VALUES.find((season) => season === event.target.value) ?? "";
      setSelectedSeason(newValue);
    },
    [],
  );

  const availableSeasons = useMemo(() => {
    return SEASONS_LIST.filter((season) => {
      return roomPictures.some((picture) => picture.season === season.value);
    });
  }, [roomPictures]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      isCentered={true}
      scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent>
        <ModalHeader>
          <HStack spacing="16px">
            <Text as="span" flexGrow={1} pl="56px" textAlign="center">
              <FormattedMessage defaultMessage="Photos" />
            </Text>

            <IconButton
              onClick={onClose}
              icon={<Icon icon="ms_close" />}
              aria-label={intl.formatMessage({ defaultMessage: "Fermer" })}
              variant="outline"
              size="sm"
              borderRadius="full"
            />
          </HStack>
        </ModalHeader>

        <ModalBody px="16px" pt="0" pb="16px">
          <Stack spacing="16px">
            {availableSeasons.length > 0 && (
              <Flex justify="flex-end">
                <HStack>
                  <Text>
                    <FormattedMessage defaultMessage="Saison:" />
                  </Text>

                  <Select
                    value={selectedSeason}
                    onChange={handleChange}
                    placeholder={intl.formatMessage({
                      defaultMessage: "Indifférent",
                    })}>
                    {availableSeasons.map((season) => (
                      <option value={season.value} key={season.value}>
                        {intl.formatMessage(SEASONS[season.value].label)}
                      </option>
                    ))}
                  </Select>
                </HStack>
              </Flex>
            )}

            <Grid
              gridTemplateColumns="repeat(2, 1fr)"
              gridRowGap="24px"
              gridColumnGap="8px">
              {sortedRoomPictures?.map((roomPictures, index) => (
                <Box
                  gridColumn={index % 3 === 0 ? "span 2" : undefined}
                  borderRadius="10px"
                  overflow="hidden"
                  backgroundColor="gray.100"
                  key={roomPictures.id}>
                  <RoomPictureLazy
                    roomPicture={roomPictures}
                    maxContainerWidth={864}
                  />

                  {(roomPictures.caption || roomPictures.copyright) && (
                    <Box mt="12px" px=".25rem">
                      {roomPictures.caption && (
                        <Text variant="text-sm">
                          {translate(roomPictures.caption)}
                        </Text>
                      )}

                      {roomPictures.copyright && (
                        <Text variant="text-sm">{roomPictures.copyright}</Text>
                      )}
                    </Box>
                  )}
                </Box>
              ))}
            </Grid>
          </Stack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
