import { Stack } from "@chakra-ui/react";
import { memo } from "react";
import { HotelViewRoomCard } from "./RoomCard";

export const HotelViewRooms = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Room").Room[]} rooms
   * @property {"week" | "night"} typeOfDuration
   * @property {() => void} onOpenBottomBar
   * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
   * @property {import("@raiden/library-ui/types/Hotel").HotelType} hotelType
   */
  /**
   * @param {Props} props
   */
  function HotelViewRooms({
    rooms,
    typeOfDuration,
    form,
    onOpenBottomBar,
    hotelType,
  }) {
    return (
      <Stack spacing="12px">
        {rooms.map((room) => (
          <HotelViewRoomCard
            room={room}
            durationUnit={typeOfDuration}
            key={room.id}
            form={form}
            onOpenBottomBar={onOpenBottomBar}
            hotelType={hotelType}
          />
        ))}
      </Stack>
    );
  },
);
