import { defineMessage } from "react-intl";

/**
 * @typedef {object} SearchHotelRank
 * @property {import("react-intl").MessageDescriptor | null} label
 * @property {string} color
 */

/**
 * @param {object} param0
 * @param {import("@raiden/library-ui/types/Hotel").HotelReviewInfos} param0.reviewInfos
 * @returns {SearchHotelRank | null}
 */
export function getSearchHotelRank({ reviewInfos }) {
  if (reviewInfos.average === null) {
    return null;
  } else if (reviewInfos.average <= 3) {
    return {
      label: null,
      color: "gray.400",
    };
  } else if (reviewInfos.average <= 6) {
    return {
      label: null,
      color: "gray.500",
    };
  } else if (reviewInfos.average <= 8) {
    return {
      label: defineMessage({ defaultMessage: "Très bien" }),
      color: "brandPrimary.300",
    };
  } else if (reviewInfos.average <= 9) {
    return {
      label: defineMessage({ defaultMessage: "Superbe" }),
      color: "brandPrimary.500",
    };
  }
  return {
    label: defineMessage({ defaultMessage: "Ils ont adoré" }),
    color: "brandPrimary.600",
  };
}
