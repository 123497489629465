import { Box, Stack } from "@chakra-ui/react";
import { CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED } from "@raiden/library-ui/constants/cartBookingReview";
import SearchInfiniteProvider from "@raiden/library-ui/contexts/SearchInfinite/SearchInfiniteProvider";
import { useSearchInfinite } from "@raiden/library-ui/contexts/SearchInfinite/useSearchInfinite";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { memo } from "react";
import { CenteredContent } from "../../../../../components/CenteredContent";
import { HotelViewReviewsResults } from "./Results";

/**
 * @param {object} params
 * @param {HotelsViewReviewSearchFormValues} params.fields
 * @param {number} params.key
 * @param {number} params.hotelId
 * @returns {string}
 */
export function generateReviewSearchUrl({ fields, key, hotelId }) {
  return generateApiUrl({
    id: "@cartsBookingsReviews.viewAny",
    query: {
      ...fields,
      context: "website",
      is_public: true,
      hotel_id: hotelId,
      state: [CARTS_BOOKING_REVIEW_STATE_VALUE_PUBLISHED],
      page: key + 1,
    },
  });
}

/**
 * @typedef {object} HotelsViewReviewSearchFormValues
 * @property {string} sort
 * @property {string} term
 * @property {string} page
 * @property {string} per_page
 */

/**
 * @type {HotelsViewReviewSearchFormValues}
 */
const DEFAULT_VALUES = {
  sort: "created_desc",
  term: "",
  page: "1",
  per_page: "5",
};
export const HotelViewReviewSearch = memo(
  /**
   * @typedef {object} Props
   * @property {number} hotelId
   */

  /**
   * @param {Props} props
   */
  function HotelViewReviewSearch({ hotelId }) {
    const page = DEFAULT_VALUES.page;
    const per_page = DEFAULT_VALUES.per_page;

    const defaultValuesFromUrl = {
      page,
      per_page,
    };

    /** @type {import("@raiden/library-ui/contexts/SearchInfinite/SearchInfiniteProvider").SearchInfiniteContextValue<HotelsViewReviewSearchFormValues>} */
    const searchInfinite = useSearchInfinite({
      defaultValues: DEFAULT_VALUES,
      initialFields: {
        ...DEFAULT_VALUES,
        ...defaultValuesFromUrl,
      },
      generateUrl: (fields, key) =>
        generateReviewSearchUrl({ fields, key, hotelId }),
      useRouterUrl: false,
      autoSubmit: true,
      keepPreviousData: true,
    });

    return (
      <Box>
        <CenteredContent>
          <SearchInfiniteProvider {...searchInfinite}>
            <Stack spacing="1.25rem">
              <HotelViewReviewsResults />
            </Stack>
          </SearchInfiniteProvider>
        </CenteredContent>
      </Box>
    );
  },
);
