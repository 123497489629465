import { apiGetErrorDetail } from "@raiden/library-ui/helpers/api";
import { memo } from "react";
import { Alert, AlertDescription } from "@chakra-ui/react";

export const CartBookingsCreateEstimateErrorComponent = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Api/ApiError").ApiError} error
   */
  /**
   * @param {Props} props
   */
  function CartBookingsCreateEstimateErrorComponent({ error }) {
    const message = apiGetErrorDetail({ error });

    return (
      <Alert status="warning">
        <AlertDescription>{message}</AlertDescription>
      </Alert>
    );
  },
);
