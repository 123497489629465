import { useApiError } from "@raiden/library-ui/components/ReactHookForm/ApiError";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import encodeFormData from "@splitfire-agency/raiden-library/dist/libraries/utils/encodeFormData";
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";

/**
 * @typedef {object} WebHotelContactCreateFormValues
 * @property {object} data
 * @property {string} data.first_name
 * @property {string} data.last_name
 * @property {string} data.phone
 * @property {string} data.phone_country
 * @property {string} data.email
 * @property {string} data.email_verify
 * @property {string} data.message
 */

/**
 * @returns {WebHotelContactCreateFormValues}
 */
export function getWebHotelContactCreateFormDefaultValues() {
  return {
    data: {
      first_name: "",
      last_name: "",
      phone: "",
      phone_country: "FR",
      email: "",
      email_verify: "",
      message: "",
    },
  };
}

/**
 * @typedef {object} Props
 * @property {number} hotelId
 * @property {() => void} [onSuccess]
 * @property {(params: {form: import("react-hook-form").UseFormReturn<WebHotelContactCreateFormValues>, submit: () => void, isLoading: boolean}) => import("react").ReactElement} children
 */
/**
 * @param {Props} props
 */
export function WebHotelContactCreateHandler({ hotelId, onSuccess, children }) {
  const intl = useIntl();

  const form = useForm({
    defaultValues: getWebHotelContactCreateFormDefaultValues(),
  });
  const { handleSubmit } = form;

  const { request, isLoading, toastError } = useRequest();

  const { resolveApiError, resetApiErrors } = useApiError();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit(async (values) => {
        if (!executeRecaptcha) {
          console.warn("Execute recaptcha not yet available");
          toastError({
            id: "invalid-recaptcha",
            status: "error",
            description: intl.formatMessage({
              defaultMessage: "Impossible de charger le module de sécurité",
            }),
            title: intl.formatMessage({
              defaultMessage: "Erreur",
            }),
            isClosable: true,
          });
          return;
        }

        const token = await executeRecaptcha("submit_form_contact");

        request(
          generateApiUrl({
            id: "@hotelcontacts.create",
            parameters: { hotelId },
          }),
          {
            method: "POST",
            body: encodeFormData({
              data: {
                ...values.data,
                email_verify: undefined,
              },
              meta: {
                recaptcha_token: token,
              },
            }),
          },
          {
            inputMode: "formData",
          },
        )
          .then((response) => {
            resetApiErrors();
            onSuccess?.();
          })
          .catch((response) => {
            resetApiErrors();
            resolveApiError({ response, form });
          });
      })();
    },
    [
      executeRecaptcha,
      form,
      handleSubmit,
      hotelId,
      intl,
      onSuccess,
      request,
      resetApiErrors,
      resolveApiError,
      toastError,
    ],
  );

  return (
    <FormProvider {...form}>
      {children({ form, submit, isLoading })}
    </FormProvider>
  );
}
