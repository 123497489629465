import { Box, HStack, Text } from "@chakra-ui/react";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import { memo } from "react";
import { useIntl } from "react-intl";
import { ExpandableContainer } from "../../../components/ExpandableContainer/ExpandableContainer";
import { HotelViewSection } from "./Section";
import { Icon } from "@raiden/library-ui/components/Icon";

export const HotelViewDescriptions = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
   * @property {import("@raiden/library-ui/types/Room").Room} [room]
   */
  /**
   * @param {Props} props
   */
  function HotelViewDescriptions({ hotel, room }) {
    const intl = useIntl();

    const translate = useTranslate();
    return (
      <>
        {(hotel.translations?.plus_1 ||
          hotel.translations?.plus_2 ||
          hotel.translations?.plus_3) && (
          <HotelViewSection
            title={intl.formatMessage({
              defaultMessage: "Les plus de l'hébergement",
            })}>
            <Box borderRadius="10px" background="gray.100" padding="12px">
              {hotel.translations.plus_1 && (
                <HStack spacing=".75rem" mb=".75rem">
                  <Box>
                    <Icon icon="ms_editor_choice" size="1.5rem" />
                  </Box>

                  <Text as="span">{translate(hotel.translations.plus_1)}</Text>
                </HStack>
              )}

              {hotel.translations.plus_2 && (
                <HStack spacing=".75rem" mb=".75rem">
                  <Box>
                    <Icon icon="ms_editor_choice" size="1.5rem" />
                  </Box>

                  <Text as="span">{translate(hotel.translations.plus_2)}</Text>
                </HStack>
              )}

              {hotel.translations.plus_3 && (
                <HStack spacing=".75rem" mb=".75rem">
                  <Box>
                    <Icon icon="ms_editor_choice" size="1.5rem" />
                  </Box>

                  <Text as="span">{translate(hotel.translations.plus_3)}</Text>
                </HStack>
              )}
            </Box>
          </HotelViewSection>
        )}

        {!room && hotel.translations?.about && (
          <HotelViewSection
            title={intl.formatMessage({
              defaultMessage: "Description",
            })}>
            <ExpandableContainer maxHeight={24 * 4}>
              <Text whiteSpace="pre-wrap">
                {translate(hotel.translations.about)}
              </Text>
            </ExpandableContainer>
          </HotelViewSection>
        )}

        {room && room.about && (
          <HotelViewSection
            title={intl.formatMessage({
              defaultMessage: "Description",
            })}>
            <ExpandableContainer maxHeight={24 * 4}>
              <Text whiteSpace="pre-wrap">{translate(room.about)}</Text>
            </ExpandableContainer>
          </HotelViewSection>
        )}

        {hotel.translations?.reception && (
          <HotelViewSection
            title={intl.formatMessage({
              defaultMessage: "Accueil",
            })}>
            <ExpandableContainer maxHeight={24 * 4}>
              <Text whiteSpace="pre-wrap">
                {translate(hotel.translations.reception)}
              </Text>
            </ExpandableContainer>
          </HotelViewSection>
        )}
      </>
    );
  },
);
