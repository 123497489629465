/**
 * Récupére l'object picture en fonction de sa position dans un hotel
 * @param {object} param0
 * @param {import("../../../types/Hotel").Hotel} param0.hotel
 * @param {number} [param0.position]
 * @returns {import("../../../types/RoomPicture").RoomPicture | undefined}
 */
export function getPictureForPosition({ hotel, position = 1 }) {
  const pictureList = hotel?.pictures ?? [];
  return pictureList.find((picture) => picture.position === position);
}
