import { Text } from "@chakra-ui/react";
import {
  hotelsTypeMessage,
  HOTELS_TYPE_VALUE_COTTAGE,
  HOTELS_TYPE_VALUE_GUEST_HOUSES,
  HOTELS_TYPE_VALUE_UNUSUAL,
} from "@raiden/library-ui/constants/hotels";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { LabelRanking } from "../../Common/LabelRanking";
import { GuestHouseInfoModal } from "../GuestHouseInfoModal/GuestHouseInfoModal";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Hotel").HotelType} type
 * @property {boolean} isNew
 * @property {boolean} highlight
 * @property {number | null} labelRanking
 * @property {boolean} isClevRoom
 */
/**
 * @param {Props} props
 */
export function HotelTags({
  type,
  isNew,
  highlight,
  labelRanking,
  isClevRoom,
}) {
  const intl = useIntl();

  const hotelType = useMemo(() => {
    if (isClevRoom && type === HOTELS_TYPE_VALUE_GUEST_HOUSES) {
      return intl.formatMessage({ defaultMessage: "Chambre clévacances" });
    }

    return type === HOTELS_TYPE_VALUE_COTTAGE
      ? intl.formatMessage({ defaultMessage: "Location de vacances" })
      : intl.formatMessage(hotelsTypeMessage, { type });
  }, [intl, isClevRoom, type]);

  return (
    <>
      <Text
        display="flex"
        alignItems="center"
        variant="text-xs"
        color="#fff"
        backgroundColor="brandPrimary.500"
        borderRadius="30px"
        whiteSpace="nowrap"
        px="0.5rem"
        py="0.1875rem">
        {hotelType}

        {type === HOTELS_TYPE_VALUE_GUEST_HOUSES && (
          <GuestHouseInfoModal
            iconColor="white"
            withClevRoom={isClevRoom}
            withGuestHouse={!isClevRoom}
            withTitle={false}
          />
        )}
      </Text>

      {isNew && (
        <Text
          display="inline-block"
          variant="text-xs"
          color="#fff"
          backgroundColor="#493E3C"
          borderRadius="30px"
          whiteSpace="nowrap"
          px="0.5rem"
          py="0.1875rem">
          <FormattedMessage defaultMessage="Nouveauté" />
        </Text>
      )}

      {highlight && (
        <Text
          display="inline-block"
          variant="text-xs"
          color="#fff"
          backgroundColor="#911F71"
          borderRadius="30px"
          whiteSpace="nowrap"
          px="0.5rem"
          py="0.1875rem">
          <FormattedMessage defaultMessage="Coup de coeur" />
        </Text>
      )}

      {labelRanking !== null &&
        labelRanking > 0 &&
        type !== HOTELS_TYPE_VALUE_UNUSUAL && (
          <LabelRanking labelRanking={labelRanking} />
        )}
    </>
  );
}
