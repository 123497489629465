/**
 * @param {object} params
 * @param {import("@raiden/library-ui/types/Room").Room} params.room
 * @param {"week" | "night"} params.durationUnit
 * @returns {{ price: number, durationUnit: "night" | "week" } | undefined}
 */
export function getRoomPrice({ room, durationUnit }) {
  if (durationUnit === "night" && room.default_prices?.default?.night) {
    return {
      price: room.default_prices.default.night,
      durationUnit: "night",
    };
  }
  if (durationUnit === "week" && room.default_prices?.default?.week) {
    return {
      price: room.default_prices.default.week,
      durationUnit: "week",
    };
  }
  if (room.default_prices?.default?.week) {
    return {
      price: room.default_prices.default.week,
      durationUnit: "week",
    };
  }
  if (room.default_prices?.default?.night) {
    return {
      price: room.default_prices.default.night,
      durationUnit: "night",
    };
  }
  return undefined;
}
