import { createContext, memo, useMemo } from "react";

export const RTrContext = createContext({
  isFirstRow: false,
  isLastRow: false,
});

export const RTrContextProvider = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} isFirstRow
   * @property {boolean} isLastRow
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function RTrContextProvider({ isFirstRow, isLastRow, children }) {
    const value = useMemo(() => {
      return {
        isFirstRow,
        isLastRow,
      };
    }, [isFirstRow, isLastRow]);

    return <RTrContext.Provider value={value}>{children}</RTrContext.Provider>;
  },
);
