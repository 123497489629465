import { Box, Button, Flex, Stack, Text } from "@chakra-ui/react";
import { memo, useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import useBottomNavBar from "../../hooks/useNavBar";
import { useResizeObserver } from "@raiden/library-ui/hooks/useResizeObserver";
import { Icon } from "@raiden/library-ui/components/Icon";

export const ExpandableContainer = memo(
  /**
   * @typedef {object} Props
   * @property {number} maxHeight
   * @property {import("react").ReactNode} children
   * @property {boolean} [withBottomNavBarMargin]
   * @property {string} [backgroundColor]
   */
  /**
   * @param {Props} props
   */
  function ExpandableContainer({
    maxHeight,
    children,
    withBottomNavBarMargin = true,
    backgroundColor = "#fff",
  }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const containerRef = useRef(/** @type {HTMLDivElement | null} */ (null));

    const handleClick = useCallback(() => {
      setIsExpanded((prev) => !prev);
    }, []);

    const { height, scrollHeight } = useResizeObserver({ ref: containerRef });

    const canExpand = scrollHeight > height || height > maxHeight;

    useEffect(() => {
      if (isExpanded && !canExpand) {
        setIsExpanded(false);
      }
    }, [canExpand, isExpanded]);

    const { navBarHeight } = useBottomNavBar();

    return (
      <Stack position="relative" spacing="8px">
        <Box
          ref={containerRef}
          position="relative"
          maxHeight={isExpanded ? `${scrollHeight}px` : `${maxHeight}px`}
          overflowY="hidden"
          transition="max-height .25s ease">
          {children}

          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            height="24px"
            opacity={isExpanded || !canExpand ? 0 : 1}
            pointerEvents="none"
            backgroundColor={backgroundColor}
            sx={{
              maskImage:
                "linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))",
            }}
            transition="all .25s ease"
          />
        </Box>

        {canExpand && (
          <Flex
            justifyContent="center"
            position={isExpanded ? "sticky" : undefined}
            bottom={`calc(${
              withBottomNavBarMargin ? navBarHeight : 0
            }px + 8px)`}>
            <Box backgroundColor="#fff" borderRadius="10px">
              <Button
                onClick={handleClick}
                rightIcon={
                  <Icon
                    icon={isExpanded ? "ms_expand_less" : "ms_expand_more"}
                    size="16px"
                  />
                }
                variant="outline"
                size="sm">
                <Text variant="button-sm">
                  {isExpanded ? (
                    <FormattedMessage defaultMessage="Voir moins" />
                  ) : (
                    <FormattedMessage defaultMessage="Voir plus" />
                  )}
                </Text>
              </Button>
            </Box>
          </Flex>
        )}
      </Stack>
    );
  },
);
