import {
  AspectRatio,
  Button,
  Stack,
  Text,
  VStack,
  Wrap,
} from "@chakra-ui/react";
import {
  HOTELS_MEDIA_TYPE_VALUE_VIDEO,
  HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT,
} from "@raiden/library-ui/constants/hotels";
import { memo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { CookiesRequired } from "../../../components/CookiesRequired/CookiesRequired";
import { MediaLoader } from "./MediaLoader";
import { HotelViewSection } from "./Section";
import { getEmbedUrl } from "@raiden/library-ui/normalizers/hotels/serializeUpdateMedias";

function getDomain({ url }) {
  return new URL(url).hostname;
}

/**
 * @typedef {object} MediaFallbackProps
 * @property {() => void} handleForceDisplay
 * @property {string} url
 * @property {() => void} openCookiesSettings
 */
/**
 * @param {MediaFallbackProps} props
 */
function MediaFallback({ handleForceDisplay, url, openCookiesSettings }) {
  return (
    <VStack
      spacing="24px"
      justifyContent="center"
      backgroundColor="#eee"
      w="full"
      h="full"
      p="16px">
      <Text variant="text-sm" textAlign="center">
        <FormattedMessage
          defaultMessage="Des cookies pourraient être déposés sur votre appareil en chargeant le contenu de {domain}."
          values={{ domain: getDomain({ url }) }}
        />
      </Text>

      <Wrap justifyContent="center">
        <Button onClick={openCookiesSettings} size="sm">
          <FormattedMessage defaultMessage="Paramètres" />
        </Button>

        <Button
          onClick={handleForceDisplay}
          size="sm"
          colorScheme="brandPrimary">
          <FormattedMessage defaultMessage="Charger" />
        </Button>
      </Wrap>
    </VStack>
  );
}

/**
 * @param {object} params
 * @param {string} params.url
 * @returns {string}
 */
function normalizeUrl({ url }) {
  return url;
}

export const HotelViewMedias = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").HotelMedia[]} virtualVisits
   * @property {import("@raiden/library-ui/types/Hotel").HotelMedia[]} videos
   */
  /**
   * @param {Props} props
   */
  function HotelViewMedias({ virtualVisits, videos }) {
    const intl = useIntl();

    if (virtualVisits.length === 0 && videos.length === 0) {
      return null;
    }

    return (
      <Stack>
        {virtualVisits.length > 0 && (
          <HotelViewSection
            title={intl.formatMessage({
              defaultMessage: "Visite virtuelle",
            })}>
            {virtualVisits.map((media, index) => {
              const url = getEmbedUrl(media.url);
              return (
                <MediaLoader
                  type={HOTELS_MEDIA_TYPE_VALUE_VIRTUAL_VISIT}
                  key={index}>
                  <AspectRatio ratio={16 / 9}>
                    <CookiesRequired
                      fallback={(args) => (
                        <MediaFallback {...args} url={url} />
                      )}>
                      <iframe
                        src={normalizeUrl({ url: url })}
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                        allowFullScreen={true}
                      />
                    </CookiesRequired>
                  </AspectRatio>
                </MediaLoader>
              );
            })}
          </HotelViewSection>
        )}

        {videos.length > 0 && (
          <HotelViewSection
            title={intl.formatMessage(
              {
                defaultMessage:
                  "{count, plural, zero {Vidéo} one {Vidéo} other {Vidéos}}",
              },
              { count: videos.length },
            )}>
            {videos.map((media, index) => {
              const url = getEmbedUrl(media.url);

              return (
                <MediaLoader type={HOTELS_MEDIA_TYPE_VALUE_VIDEO} key={index}>
                  <AspectRatio ratio={16 / 9}>
                    <CookiesRequired
                      fallback={(args) => (
                        <MediaFallback {...args} url={url} />
                      )}>
                      <iframe
                        src={normalizeUrl({ url: url })}
                        width="100%"
                        height="100%"
                        style={{ border: "none" }}
                        allowFullScreen={true}
                      />
                    </CookiesRequired>
                  </AspectRatio>
                </MediaLoader>
              );
            })}
          </HotelViewSection>
        )}
      </Stack>
    );
  },
);
