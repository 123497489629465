import { Flex, HStack, Stack, Text } from "@chakra-ui/react";
import BookingReviewAverageNote from "@raiden/library-ui/components/Booking/Review/AverageRate";
import BookingReviewRate from "@raiden/library-ui/components/Booking/Review/Rate";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { getSearchHotelRank } from "../../../../helpers/search/getSearchHotelRank";
import { HotelViewReviewSearch } from "./Search";

export const HotelViewReviews = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Hotel").HotelReviewInfos} [reviewInfos]
   * @property {number} hotelId
   */
  /**
   * @param {Props} props
   */
  function HotelViewReviews({ reviewInfos, hotelId }) {
    const intl = useIntl();

    const rank = useMemo(() => {
      return reviewInfos ? getSearchHotelRank({ reviewInfos }) : null;
    }, [reviewInfos]);

    return (
      <Stack spacing="16px">
        <Stack
          flexDir={{ base: "column", sm: "row" }}
          spacing=".5rem"
          backgroundColor="brandPrimary.100"
          borderRadius="10px">
          <Flex>
            {reviewInfos?.average && (
              <BookingReviewAverageNote
                averageRate={reviewInfos?.average}
                width="64px"
                fontSize="1.5rem"
              />
            )}

            <Flex direction="column" ml=".5rem" justifyContent="center">
              {rank && rank?.label !== null && (reviewInfos?.count ?? 0) > 0 && (
                <Text color="brandPrimary.600" whiteSpace="nowrap">
                  {intl.formatMessage(rank.label)}
                </Text>
              )}

              {(reviewInfos?.count ?? 0) > 0 && (
                <Text
                  variant="text-sm"
                  textDecoration="underline"
                  color="gray.500"
                  whiteSpace="nowrap">
                  <FormattedMessage
                    defaultMessage="Fondée sur {count} {count, plural, =0 {avis} one {avis} other {avis}}"
                    values={{
                      count: intl.formatNumber(reviewInfos?.count ?? 0),
                    }}
                  />
                </Text>
              )}
            </Flex>
          </Flex>

          <HStack justifyContent="space-evenly" width="full">
            <BookingReviewRate
              label={intl.formatMessage({ defaultMessage: "Accueil" })}
              rate={reviewInfos?.greeting_average}
            />

            <BookingReviewRate
              label={intl.formatMessage({ defaultMessage: "Propreté" })}
              rate={reviewInfos?.fairness_average}
            />

            <BookingReviewRate
              label={intl.formatMessage({ defaultMessage: "Confort" })}
              rate={reviewInfos?.cosiness_average}
            />

            <BookingReviewRate
              label={intl.formatMessage({ defaultMessage: "Lieu" })}
              rate={reviewInfos?.location_average}
            />

            <BookingReviewRate
              label={intl.formatMessage({ defaultMessage: "Qualité/Prix" })}
              rate={reviewInfos?.quality_average}
            />
          </HStack>
        </Stack>

        {(reviewInfos?.count ?? 0) > 0 && (
          <HotelViewReviewSearch hotelId={hotelId} />
        )}
      </Stack>
    );
  },
);
