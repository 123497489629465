import { Box, useDisclosure } from "@chakra-ui/react";
import { COOKIES_NAME_VALUE_ACCEPT_COOKIES } from "@raiden/library-ui/constants/cookies";
import { useCallback, useState } from "react";
import { useCookies } from "../../hooks/useCookies";
import { CookiesSettingsModal } from "../CookiesSettingsModal/CookiesSettingsModal";

/**
 * @typedef {object} Props
 * @property {(params: { handleForceDisplay: () => void, openCookiesSettings: () => void }) => import("react").ReactNode} fallback
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function CookiesRequired({ fallback, children }) {
  const {
    isOpen: isOpenModal,
    onOpen: onOpenModal,
    onClose: onCloseModal,
  } = useDisclosure();

  const { cookies } = useCookies();

  const acceptCookies = cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES] === "1";

  const [forceDisplay, setForceDisplay] = useState(false);

  const handleForceDisplay = useCallback(() => {
    setForceDisplay(true);
  }, []);

  return (
    <>
      <Box>
        {acceptCookies || forceDisplay
          ? children
          : fallback({ handleForceDisplay, openCookiesSettings: onOpenModal })}
      </Box>

      <CookiesSettingsModal isOpen={isOpenModal} onClose={onCloseModal} />
    </>
  );
}
