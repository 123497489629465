import { Button, Text } from "@chakra-ui/react";
import { memo, useCallback } from "react";

export const ButtonSection = memo(
  /**
   * @typedef {object} Props
   * @property {string} sectionId
   * @property {string} title
   * @property {string | null} highlightedSection
   */
  /**
   * @param {Props} props
   */
  function ButtonSection({ sectionId, title, highlightedSection }) {
    const handleClickTab = useCallback(
      /** @type {import("react").MouseEventHandler<HTMLButtonElement>} */
      () => {
        const element = document.getElementById(sectionId);
        if (element) {
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
          });
        }
      },
      [sectionId],
    );

    return (
      <Button
        size="sm"
        flexShrink={0}
        colorScheme={
          highlightedSection === sectionId ? "brandPrimary" : undefined
        }
        variant="outline"
        onClick={handleClickTab}>
        <Text color="inherit" fontWeight={400}>
          {title}
        </Text>
      </Button>
    );
  },
);
