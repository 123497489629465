import dayjs from "dayjs";
/**
 * Détermine si le timeout de l'oack
 * @param {object} param0
 * @param {import("../../../../types/CartBookingEstimation").BookingEstimation} param0.bookingEstimation
 */
export default function getOwnerAcknowledgmentTimeout({ bookingEstimation }) {
  /**
   *  si now() + delay > min_checkin
   *  alors le délai devient min_checkin - now() en heure arrondi à l'entier inférieur (floor)
   */

  const now = dayjs();

  const minCheckin = dayjs(bookingEstimation?.checkin);

  let delay = bookingEstimation?.owner_acknowledgment_timeout ?? 0;

  const addWithDelay = now.add(delay, "hour");

  if (addWithDelay.isAfter(minCheckin)) {
    delay = now.diff(minCheckin, "hour");
  }

  return delay;
}
