import { defineMessage } from "react-intl";

export const CUSTOMER_TRAVELERS_TYPE_VALUE_SOCIETY = "society";
export const CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR = "particular";
export const CUSTOMER_TRAVELERS_TYPE_VALUE_INSTITUTION = "institutional";
export const CUSTOMER_TRAVELERS_TYPE_VALUE_ASSOCIATION = "association";

export const CUSTOMER_TRAVELERS_TYPES = {
  [CUSTOMER_TRAVELERS_TYPE_VALUE_SOCIETY]: {
    id: CUSTOMER_TRAVELERS_TYPE_VALUE_SOCIETY,
  },
  [CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR]: {
    id: CUSTOMER_TRAVELERS_TYPE_VALUE_PARTICULAR,
  },
  [CUSTOMER_TRAVELERS_TYPE_VALUE_INSTITUTION]: {
    id: CUSTOMER_TRAVELERS_TYPE_VALUE_INSTITUTION,
  },
  [CUSTOMER_TRAVELERS_TYPE_VALUE_ASSOCIATION]: {
    id: CUSTOMER_TRAVELERS_TYPE_VALUE_ASSOCIATION,
  },
};

export const CUSTOMER_TRAVELERS_TYPES_LIST = Object.values(
  CUSTOMER_TRAVELERS_TYPES,
);

export const customerTravelersTypeMessage = defineMessage({
  defaultMessage:
    "{type, select, society {société} particular {particulier} institutional {institutionnel} association {association} other {inconnu}}",
});

export const CUSTOMER_TRAVELERS_GENDER_VALUE_MALE = "male";
export const CUSTOMER_TRAVELERS_GENDER_VALUE_FEMALE = "female";
export const CUSTOMER_TRAVELERS_GENDER_VALUE_COUPLE = "couple";
export const CUSTOMER_TRAVELERS_GENDER_VALUE_UNKNOWN = "unknown";

export const CUSTOMER_TRAVELERS_GENDERS = {
  [CUSTOMER_TRAVELERS_GENDER_VALUE_MALE]: {
    id: CUSTOMER_TRAVELERS_GENDER_VALUE_MALE,
  },
  [CUSTOMER_TRAVELERS_GENDER_VALUE_FEMALE]: {
    id: CUSTOMER_TRAVELERS_GENDER_VALUE_FEMALE,
  },
  [CUSTOMER_TRAVELERS_GENDER_VALUE_COUPLE]: {
    id: CUSTOMER_TRAVELERS_GENDER_VALUE_COUPLE,
  },
  [CUSTOMER_TRAVELERS_GENDER_VALUE_UNKNOWN]: {
    id: CUSTOMER_TRAVELERS_GENDER_VALUE_UNKNOWN,
  },
};

export const CUSTOMER_TRAVELERS_GENDER_LIST = Object.values(
  CUSTOMER_TRAVELERS_GENDERS,
);

export const customerTravelersGenderMessage = defineMessage({
  defaultMessage:
    "{gender, select, male {M.} female {Mme.} couple {M. et Mme.} unknown {Non précisé} other {gender}}",
});
