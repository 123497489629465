import { AspectRatio, Box, HStack, Stack, Text } from "@chakra-ui/react";
import PampaImage from "@raiden/library-ui/components/PampaImage";
import { CUSTOMER_SPOKEN_LANGUAGES } from "@raiden/library-ui/constants/locales";
import useTranslate from "@raiden/library-ui/hooks/useTranslate";
import generateFrontPath from "@raiden/library-ui/libraries/utils/generateFrontPath";
import { memo, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Href } from "../../../components/Href";

export const HotelViewCustomer = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/Customer").Customer} customer
   * @property {boolean} withPublicInfo
   * @property {boolean} withWebsite
   * @property {boolean} withWebsiteInDescription
   */
  /**
   * @param {Props} props
   */
  function HotelViewCustomer({
    customer,
    withPublicInfo,
    withWebsite,
    withWebsiteInDescription,
  }) {
    const intl = useIntl();

    const translate = useTranslate();

    const languages = useMemo(() => {
      const strLanguagesArr = (customer.languages ?? []).map((code) => {
        const foundLanguage = CUSTOMER_SPOKEN_LANGUAGES.find(
          (spokenLanguage) => spokenLanguage.code === code,
        );
        return foundLanguage ? intl.formatMessage(foundLanguage.name) : code;
      });
      return intl.formatList(strLanguagesArr, { type: "conjunction" });
    }, [customer.languages, intl]);

    return (
      <Stack spacing="16px">
        <HStack>
          <AspectRatio
            ratio={1}
            flexShrink={0}
            w="48px"
            borderRadius="full"
            overflow="hidden">
            <PampaImage
              image={customer.avatar}
              mode="crop"
              format="default"
              placeholderSrc={generateFrontPath({
                id: "@front.internal-assets",
                parameters: { filePath: "placeholder-user.svg" },
                includeBasePath: true,
              })}
              alt=""
            />
          </AspectRatio>

          <Box>
            <Text>{customer.public_name}</Text>

            <Text variant="text-sm">
              <FormattedMessage
                defaultMessage="Propriétaire Clévacances depuis {date}"
                values={{
                  date: intl.formatDate(customer.created_at, {
                    year: "numeric",
                    month: "long",
                  }),
                }}
              />
            </Text>

            {customer.languages !== null && customer.languages.length > 0 && (
              <Text variant="text-sm">
                <FormattedMessage
                  defaultMessage="{languagesCount, plural, one {Langue} other {Langues}}: {languages}"
                  values={{
                    languagesCount: customer.languages.length,
                    languages,
                  }}
                />
              </Text>
            )}

            {withWebsite && customer.website && withWebsiteInDescription && (
              <Href
                href={customer.website ?? undefined}
                target="_blank"
                rel="noopener noreferrer">
                <Text
                  color="brandPrimary.600"
                  variant="text-sm"
                  textDecoration="underline">
                  {intl.formatMessage({
                    defaultMessage: "Voir le site internet du propriétaire",
                  })}
                </Text>
              </Href>
            )}
          </Box>
        </HStack>

        {withWebsite && customer.website && !withWebsiteInDescription && (
          <Href
            href={customer.website ?? undefined}
            target="_blank"
            rel="noopener noreferrer">
            <Text
              color="brandPrimary.600"
              textAlign="center"
              textDecoration="underline">
              {intl.formatMessage({
                defaultMessage: "Voir le site internet du propriétaire",
              })}
            </Text>
          </Href>
        )}

        {withPublicInfo && customer.public_infos && (
          <Text variant="text-sm" whiteSpace="pre-wrap">
            {translate(customer.public_infos)}
          </Text>
        )}
      </Stack>
    );
  },
);
