import { AnimatePresence, motion } from "framer-motion";

const HEIGHT_VARIANTS = {
  visible: {
    height: "auto",
    transition: { when: "beforeChildren", type: "tween", duration: 0.25 },
  },
  hidden: {
    height: 0,
    transition: { when: "afterChildren", type: "tween", duration: 0.25 },
  },
};

const OPACITY_VARIANTS = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

/**
 * @typedef {object} Props
 * @property {boolean} isVisible
 * @property {boolean} [initial]
 * @property {import("react").ReactNode} children
 */
/**
 * @param {Props} props
 */
export function RevealVertically({ isVisible, initial = false, children }) {
  return (
    <AnimatePresence initial={initial}>
      {isVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={HEIGHT_VARIANTS}>
          <motion.div variants={OPACITY_VARIANTS}>{children}</motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
