import { Box, Button, DarkMode } from "@chakra-ui/react";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import { RoomPicturesModal } from "../../../components/RoomPictures/Modal";

export const ButtonShowAllPictures = memo(
  /**
   * @typedef {object} Props
   * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} roomPictures
   * @property {boolean} isOpen
   * @property {() => void} onOpen
   * @property {() => void} onClose
   */
  /**
   * @param {Props} props
   */
  function ButtonShowAllPictures({ roomPictures, isOpen, onOpen, onClose }) {
    return (
      <>
        <DarkMode>
          <Box
            backdropFilter="blur(4px)"
            backgroundColor="rgba(0, 0, 0, .25)"
            borderRadius="10px">
            <Button onClick={onOpen} variant="ghost">
              <FormattedMessage
                defaultMessage="Voir {count, plural, one {la photo} other { les # photos}}"
                values={{ count: roomPictures.length }}
              />
            </Button>
          </Box>
        </DarkMode>

        <RoomPicturesModal
          roomPictures={roomPictures}
          isOpen={isOpen}
          onClose={onClose}
        />
      </>
    );
  },
);
