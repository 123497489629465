import {
  Box,
  Button,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Wrap,
} from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import generateFrontUrl from "@raiden/library-ui/libraries/utils/generateFrontUrl";
import { useCallback, useRef } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useWatch } from "react-hook-form";
import useLocale from "@raiden/library-ui/hooks/useLocale";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {import("react-hook-form").UseFormReturn<import("../../CartBookings/Create/Handler").CartBookingsCreateFormValues>} form
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function ModalWidgetSncf({ isOpen, onClose: _onClose, form, hotel }) {
  const intl = useIntl();

  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const checkin = useWatch({ control: form.control, name: "checkin" });

  const onClose = useCallback(() => {
    _onClose();
  }, [_onClose]);

  const { locale } = useLocale();

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
      size="2xl">
      <ModalOverlay />

      <ModalContent mx="1rem">
        <ModalHeader borderBottomWidth="1px" p="1rem">
          <HStack w="full">
            <Box flexGrow={1}>
              <Text variant="h4-public">
                <FormattedMessage defaultMessage="Réservez votre voyage en train" />
              </Text>
            </Box>

            <IconButton
              onClick={onClose}
              variant="outline"
              borderRadius="full"
              icon={<Icon icon="ms_close" />}
              alignSelf="flex-start"
              aria-label={intl.formatMessage({
                defaultMessage: "Fermer",
              })}
            />
          </HStack>
        </ModalHeader>

        <ModalBody py="32px">
          <iframe
            width="100%"
            height="400px"
            src={generateFrontUrl({
              id: "@front.api.sncf",
              query: { checkin, city: hotel.address?.city, locale },
            })}
          />
        </ModalBody>

        <ModalFooter borderTopWidth="1px">
          <Wrap>
            <Button onClick={onClose} colorScheme="brandSecondary">
              <FormattedMessage defaultMessage="Fermer" />
            </Button>
          </Wrap>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
