import { HStack, Stack, Text } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useMemo } from "react";
import { FormattedNumber, useIntl } from "react-intl";

/**
 * @typedef {object} BookingEstimationDetail
 * @property {string} name
 * @property {number | string} value
 */

/**
 * @param {object} params
 * @param {import("react-intl").IntlShape} params.intl
 * @param {number | null} params.touristTaxAmount
 * @param {boolean} params.shouldRenderTouristTaxAmount
 * @returns {BookingEstimationDetail[]}
 */
function getTouristTaxAmount({
  intl,
  touristTaxAmount,
  shouldRenderTouristTaxAmount,
}) {
  return touristTaxAmount !== null && shouldRenderTouristTaxAmount
    ? [
        {
          name: intl.formatMessage({ defaultMessage: "Taxe de séjour" }),
          value:
            touristTaxAmount === 0
              ? intl.formatMessage({ defaultMessage: "Exonérée" })
              : touristTaxAmount,
        },
      ]
    : [];
}

/**
 * @param {object} params
 * @param {import("react-intl").IntlShape} params.intl
 * @param {number | null} params.markupAmount
 * @param {boolean} params.shouldRenderMarkupAmount
 * @returns {BookingEstimationDetail[]}
 */
function getMarkupAmount({ intl, markupAmount, shouldRenderMarkupAmount }) {
  return markupAmount !== null && shouldRenderMarkupAmount
    ? [
        {
          name: intl.formatMessage({ defaultMessage: "Frais de service" }),
          value: markupAmount,
        },
      ]
    : [];
}

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/CartBookingEstimation").BookingEstimation} bookingEstimation
 * @property {boolean} shouldRenderTouristTaxAmount
 * @property {boolean} shouldRenderMarkupAmount
 */
/**
 * @param {Props} props
 */
export function CartBookingsEstimationDetail({
  bookingEstimation,
  shouldRenderTouristTaxAmount,
  shouldRenderMarkupAmount,
}) {
  const intl = useIntl();

  const nights = useMemo(() => {
    return dayjs(bookingEstimation.checkout).diff(
      dayjs(bookingEstimation.checkin),
      "day",
    );
  }, [bookingEstimation.checkin, bookingEstimation.checkout]);

  const details = useMemo(() => {
    /** @type {BookingEstimationDetail[]} */
    const details = [
      {
        name: intl.formatMessage(
          {
            defaultMessage:
              " {count} {count, plural, one {nuit} other {nuits}}",
          },
          { count: nights },
        ),
        value: bookingEstimation.periods_amount,
      },
      ...bookingEstimation.discounts.map((discount) => ({
        name: discount.name,
        value: -discount.amount,
      })),
      ...bookingEstimation.services.map((service) => ({
        name: `${service.quantity} × ${service.name}`,
        value: service.total,
      })),
      ...getTouristTaxAmount({
        intl,
        touristTaxAmount: bookingEstimation.tourist_tax_amount,
        shouldRenderTouristTaxAmount,
      }),
      ...getMarkupAmount({
        intl,
        markupAmount: bookingEstimation.markup_amount,
        shouldRenderMarkupAmount,
      }),
    ];
    return details;
  }, [
    bookingEstimation.discounts,
    bookingEstimation.markup_amount,
    bookingEstimation.periods_amount,
    bookingEstimation.services,
    bookingEstimation.tourist_tax_amount,
    intl,
    nights,
    shouldRenderMarkupAmount,
    shouldRenderTouristTaxAmount,
  ]);

  return (
    <Stack spacing="0">
      {details.map((detail, index) => (
        <HStack key={index}>
          <Text flexGrow={1} variant="text-sm">
            {detail.name}
          </Text>

          <Text textAlign="right" variant="text-sm">
            {typeof detail.value === "number" ? (
              <FormattedNumber
                value={detail.value}
                style="currency"
                currency="EUR"
              />
            ) : (
              detail.value
            )}
          </Text>
        </HStack>
      ))}
    </Stack>
  );
}
