import {
  IconButton,
  useDisclosure,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  ModalHeader,
  Stack,
  Box,
} from "@chakra-ui/react";
import { useIntl } from "react-intl";
import { Icon } from "@raiden/library-ui/components/Icon";
import { memo } from "react";

export const GuestHouseInfoModal = memo(
  /**
   * @typedef {object} Props
   * @property {boolean} [withClevRoom]
   * @property {boolean} [withGuestHouse]
   * @property {boolean} [withTitle]
   * @property {string} [iconColor]
   */

  /**
   * @param {Props} props
   */
  function GuestHouseInfoModal({
    withClevRoom = true,
    withGuestHouse = true,
    withTitle = true,
    iconColor = "gray.500",
  }) {
    const intl = useIntl();

    const { isOpen, onOpen, onClose } = useDisclosure();

    function handleClick(e) {
      e?.preventDefault();
      e?.stopPropagation();
      onOpen();
    }

    return (
      <>
        <IconButton
          maxH="16px"
          minW="unset"
          onClick={handleClick}
          icon={<Icon icon="ms_info" />}
          color={iconColor}
          ml="0.5rem"
          variant="unstyled"
          size="xs"
          aria-label={intl.formatMessage({
            defaultMessage: "Détail",
          })}
        />

        <Modal isOpen={isOpen} onClose={onClose} size="lg">
          <ModalOverlay />

          <ModalContent>
            <ModalHeader>
              {withClevRoom &&
                withGuestHouse &&
                intl.formatMessage({
                  defaultMessage: "Chambre d'hotes / Chambre Clévacances",
                })}

              {!withClevRoom &&
                withGuestHouse &&
                intl.formatMessage({
                  defaultMessage: "Chambre d'hotes",
                })}

              {withClevRoom &&
                !withGuestHouse &&
                intl.formatMessage({
                  defaultMessage: "Chambre Clévacances",
                })}
            </ModalHeader>

            <ModalCloseButton />

            <ModalBody>
              <Stack spacing="1.5rem">
                {withGuestHouse && (
                  <Box>
                    {withTitle && (
                      <Text variant="h4" fontWeight={600} mb=".625rem">
                        {intl.formatMessage({
                          defaultMessage: "Chambres d'hôtes",
                        })}
                      </Text>
                    )}

                    <Text variant="text-sm">
                      {intl.formatMessage({
                        defaultMessage:
                          "La Chambre d'hôtes est la fourniture de la nuitée et du petit déjeuner. Elle est située chez l’habitant dans sa résidence principale. Maximum 5 chambres et 15 personnes par structure.",
                      })}
                    </Text>
                  </Box>
                )}

                {withClevRoom && (
                  <Box>
                    {withTitle && (
                      <Text variant="h4" fontWeight={600} mb=".625rem">
                        {intl.formatMessage({
                          defaultMessage: "Chambres Clévacances",
                        })}
                      </Text>
                    )}

                    <Text variant="text-sm">
                      {intl.formatMessage({
                        defaultMessage:
                          'La Chambre Clévacances est la fourniture de la nuitée et du petit déjeuner (servi par le propriétaire ou préparé par le client). Elle est indépendante, et peut être située à une adresse différente de l\'habitation du propriétaire ou du mandataire, au-dessus d\'un restaurant ou d\'une ferme auberge. Si la structure permet l’accueil de plus de 15 personnes, elle devra respecter la réglementation en vigueur relative aux "Etablissement Recevant du Public" (ERP). La marque Clévacances ne prendra en compte que les structures dont la capacité maximale est de 9 chambres ou 25 personnes par structure. Cette gamme "Chambre Clévacances" correspond à une appellation du label Clévacances, et ne doit pas être assimilée à une "Chambre d\'hôtes".',
                      })}
                    </Text>
                  </Box>
                )}
              </Stack>
            </ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  },
);
