import { createContext, memo, useMemo } from "react";

/**
 * @typedef {object} RTableContext
 * @property {import(".").RTableColumn[]} columns
 * @property {string} responsiveBreakpoint
 * @property {boolean} isStriped
 * @property {boolean} useDivider
 * @property {boolean} useDesktopDivider
 * @property {string} cellPadding
 */

/** @type {RTableContext} */
const DEFAULT_VALUE = {
  columns: [],
  responsiveBreakpoint: "500px",
  isStriped: false,
  useDivider: true,
  useDesktopDivider: false,
  cellPadding: "8px",
};

export const RTableContext = createContext(DEFAULT_VALUE);

export const RTableContextProvider = memo(
  /**
   * @typedef {object} Props
   * @property {import(".").RTableColumn[]} columns
   * @property {string} responsiveBreakpoint
   * @property {boolean} isStriped
   * @property {boolean} useDivider
   * @property {boolean} useDesktopDivider
   * @property {string} cellPadding
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function RTableContextProvider({
    columns,
    responsiveBreakpoint,
    isStriped,
    useDivider,
    useDesktopDivider,
    cellPadding,
    children,
  }) {
    const value = useMemo(() => {
      return {
        columns,
        responsiveBreakpoint,
        isStriped,
        useDivider,
        useDesktopDivider,
        cellPadding,
      };
    }, [
      cellPadding,
      columns,
      isStriped,
      responsiveBreakpoint,
      useDesktopDivider,
      useDivider,
    ]);

    return (
      <RTableContext.Provider value={value}>{children}</RTableContext.Provider>
    );
  },
);
