import { Box, Flex, HStack, Stack, Text } from "@chakra-ui/react";
import { DataHandler } from "@raiden/library-ui/components/DataHandler";
import {
  BOOKINGS_NATURE_VALUE_PAR,
  BOOKINGS_NATURE_VALUE_PRO,
} from "@raiden/library-ui/constants/bookings";
import { useApi } from "@raiden/library-ui/hooks/useApi";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useMemo } from "react";
import { useWatch } from "react-hook-form";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { CartBookingsCreateEstimateErrorComponent } from "./ErrorComponent";
import { CartBookingsEstimationDetail } from "./EstimationDetail";
import { CartBookingsEstimationOack } from "./EstimationOack";
/**
 * soustrait la taxe de séjour ainsi que les frais de dossier sur le montant total quand ils ne doivent pas être affichés
 * @param {object} param0
 * @param {import("@raiden/library-ui/types/CartBookingEstimation").BookingEstimation | undefined} param0.bookingEstimation
 * @param {boolean} param0.shouldRenderMarkupAmount
 * @param {boolean} param0.shouldRenderTouristTaxAmount
 * @returns {number | null}
 */
function getTripAmount({
  bookingEstimation,
  shouldRenderMarkupAmount,
  shouldRenderTouristTaxAmount,
}) {
  if (!bookingEstimation) {
    return null;
  }
  let tripAmount = bookingEstimation.trip_amount;
  const markupAmount = bookingEstimation.markup_amount;
  const touristTaxAmount = bookingEstimation.tourist_tax_amount;
  if (!shouldRenderMarkupAmount && markupAmount) {
    tripAmount -= markupAmount;
  }
  if (!shouldRenderTouristTaxAmount && touristTaxAmount) {
    tripAmount -= touristTaxAmount;
  }
  return tripAmount;
}

/**
 * @typedef {object} Props
 * @property {import("react-hook-form").UseFormReturn<import("./Handler").CartBookingsCreateFormValues>} form
 * @property {boolean} shouldRenderTouristTaxAmount
 * @property {boolean} shouldRenderMarkupAmount
 * @property {boolean} isBookableOnline
 */
/**
 * @param {Props} props
 */
export function BookingsCreateEstimation({
  form,
  shouldRenderTouristTaxAmount,
  shouldRenderMarkupAmount,
  isBookableOnline,
}) {
  const roomId = useWatch({ control: form.control, name: "room_id" });
  const checkin = useWatch({ control: form.control, name: "checkin" });
  const checkout = useWatch({ control: form.control, name: "checkout" });
  const adults = useWatch({ control: form.control, name: "adults" });
  const children = useWatch({ control: form.control, name: "children" });
  const babies = useWatch({ control: form.control, name: "babies" });
  const pets = useWatch({ control: form.control, name: "pets" });
  const nature = useWatch({ control: form.control, name: "travel_for_work" })
    ? BOOKINGS_NATURE_VALUE_PRO
    : BOOKINGS_NATURE_VALUE_PAR;

  const body = useMemo(() => {
    return {
      data: {
        source: "web",
        workflow: "system",
        room_id: roomId,
        checkin,
        checkout,
        adults,
        children,
        babies,
        pets,
        nature,
      },
    };
  }, [adults, babies, checkin, checkout, children, nature, pets, roomId]);

  const bodyStringified = useMemo(() => {
    return JSON.stringify(body);
  }, [body]);

  /** @type {import("@raiden/library-ui/hooks/useApi").UseApi<import("@raiden/library-ui/types/CartBookingEstimation").BookingEstimation>} */
  const { swrResponse: swrResponseEstimate } = useApi(
    checkin !== "" && checkout !== ""
      ? [generateApiUrl({ id: "@cartsBookings.estimate" }), bodyStringified]
      : null,
    {
      method: "POST",
      body: body,
      swrConfig: {
        keepPreviousData: false,
        onSuccess: (response) => {
          form.setValue(
            "services",
            response.data.services.map((service) => ({
              service_id: service.service_id,
              quantity: service.quantity,
            })),
            {
              shouldDirty: true,
            },
          );
        },
      },
    },
  );

  const isDiscounted =
    (swrResponseEstimate.data?.data?.discounts_amount ?? 0) > 0;

  const tripAmount = useMemo(() => {
    return getTripAmount({
      bookingEstimation: swrResponseEstimate.data?.data,
      shouldRenderMarkupAmount,
      shouldRenderTouristTaxAmount,
    });
  }, [
    shouldRenderMarkupAmount,
    shouldRenderTouristTaxAmount,
    swrResponseEstimate.data?.data,
  ]);

  return (
    <DataHandler
      swrResponse={swrResponseEstimate}
      ErrorComponent={CartBookingsCreateEstimateErrorComponent}
      wrapper={({ children }) => <Box minH="40px">{children}</Box>}>
      {({ data: bookingEstimation }) => {
        return (
          <Stack>
            <CartBookingsEstimationDetail
              bookingEstimation={bookingEstimation}
              shouldRenderTouristTaxAmount={shouldRenderTouristTaxAmount}
              shouldRenderMarkupAmount={shouldRenderMarkupAmount}
            />

            <HStack minH="40px">
              <Flex flexGrow={1} justify="flex-end">
                <Text color="gray.500" variant="text-sm" alignSelf="flex-end">
                  {isDiscounted && tripAmount ? (
                    <FormattedMessage
                      defaultMessage="Votre séjour à partir de {discountedPrice}"
                      values={{
                        discountedPrice: (
                          <Text
                            as="span"
                            fontSize="0.875rem"
                            fontWeight={400}
                            lineHeight="normal"
                            textDecoration="line-through"
                            color="brandSecondary.500">
                            <FormattedNumber
                              value={
                                tripAmount + bookingEstimation.discounts_amount
                              }
                              style="currency"
                              currency="EUR"
                            />
                          </Text>
                        ),
                      }}
                    />
                  ) : (
                    <FormattedMessage defaultMessage="Votre séjour à partir de" />
                  )}
                </Text>
              </Flex>

              {tripAmount && (
                <Text
                  fontSize="1.75rem"
                  fontWeight={400}
                  lineHeight="normal"
                  letterSpacing="0.035rem"
                  textAlign="right"
                  color="brandPrimary.500">
                  <FormattedNumber
                    value={tripAmount}
                    style="currency"
                    currency="EUR"
                  />
                </Text>
              )}
            </HStack>

            {isBookableOnline && (
              <CartBookingsEstimationOack
                bookingEstimation={bookingEstimation}
              />
            )}
          </Stack>
        );
      }}
    </DataHandler>
  );
}
