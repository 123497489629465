import {
  Button,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Wrap,
} from "@chakra-ui/react";
import { COOKIES_NAME_VALUE_ACCEPT_COOKIES } from "@raiden/library-ui/constants/cookies";
import { useCallback, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useCookies } from "../../hooks/useCookies";
import cookie from "cookie";
import getBasePath from "@splitfire-agency/raiden-library/dist/libraries/utils/getBasePath";

/**
 * @typedef {object} Props
 * @property {boolean} isOpen
 * @property {() => void} onClose
 */
/**
 * @param {Props} props
 */
export function CookiesSettingsModal({ isOpen, onClose }) {
  /** @type {import("react").MutableRefObject<HTMLButtonElement | null>} */
  const ref = useRef(null);

  const { cookies, setCookies } = useCookies();

  const [areCookiesAllowed, setAreCookiesAllowed] = useState(
    cookies[COOKIES_NAME_VALUE_ACCEPT_COOKIES] === "1",
  );

  const handleSubmit = useCallback(() => {
    setCookies({
      [COOKIES_NAME_VALUE_ACCEPT_COOKIES]: areCookiesAllowed ? "1" : "0",
    });
    document.cookie = cookie.serialize(COOKIES_NAME_VALUE_ACCEPT_COOKIES, "1", {
      path: getBasePath(process.env.NEXT_PUBLIC_FRONT_BASE_PATH ?? ""),
      secure: true,
    });
    onClose();
  }, [areCookiesAllowed, onClose, setCookies]);

  return (
    <Modal
      initialFocusRef={ref}
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside">
      <ModalOverlay />

      <ModalContent mx="1rem">
        <ModalHeader>
          <FormattedMessage defaultMessage="Paramètres des cookies" />
        </ModalHeader>

        <ModalCloseButton />

        <ModalBody>
          <FormControl display="flex" alignItems="center">
            <FormLabel flexGrow={1} htmlFor="email-alerts" mb="0">
              <FormattedMessage defaultMessage="Autoriser les cookies" />
            </FormLabel>

            <Switch
              id="email-alerts"
              isChecked={areCookiesAllowed}
              onChange={() => {
                setAreCookiesAllowed((currentValue) => !currentValue);
              }}
            />
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Wrap>
            <Button onClick={onClose} colorScheme="gray">
              <FormattedMessage defaultMessage="Annuler" />
            </Button>

            <Button
              ref={ref}
              onClick={handleSubmit}
              colorScheme="brandSecondary">
              <FormattedMessage defaultMessage="Enregistrer" />
            </Button>
          </Wrap>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
