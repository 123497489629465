/**
 * @param {object} params
 * @param {import("../../../types/Departement").Departement} params.department
 * @param {string} [params.locale]
 * @param {string} [params.defaultLocale]
 * @returns {string}
 */
export function getDepartmentSlug({ department, locale, defaultLocale }) {
  let slug = `${department.id}-`;

  if (department.slug) {
    if (locale && department.slug[locale]) {
      slug += department.slug[locale];
    } else if (defaultLocale && department.slug[defaultLocale]) {
      slug += department.slug[defaultLocale];
    }
  }

  return slug;
}
