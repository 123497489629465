import { Button, Flex, Stack, useDisclosure } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import { WebHotelContactCreateModal } from "../../WebHotelContact/Create/Modal";
import { HotelViewCustomer } from "./Customer";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/Hotel").Hotel} hotel
 * @property {{ isOpen: boolean, onOpen: () => void, onClose: () => void}} [disclosure]
 * @property {boolean} withContactLink
 * @property {boolean} withWebsite
 * @property {boolean} withWebsiteInDescription
 */
/**
 * @param {Props} props
 */
export function ContactCustomer({
  hotel,
  disclosure: _disclosure,
  withContactLink,
  withWebsite,
  withWebsiteInDescription,
}) {
  const locaDisclosure = useDisclosure();

  const disclosure = _disclosure ?? locaDisclosure;

  const { isOpen, onOpen, onClose } = disclosure;

  return (
    <>
      <WebHotelContactCreateModal
        hotelId={hotel.id}
        isOpen={isOpen}
        onClose={onClose}
        customer={hotel.customer ?? null}
        withWebsite={withWebsite}
      />

      <Stack spacing="12px">
        {hotel.customer && (
          <HotelViewCustomer
            customer={hotel.customer}
            withPublicInfo={false}
            withWebsite={withWebsite}
            withWebsiteInDescription={withWebsiteInDescription}
          />
        )}

        {withContactLink && (
          <Flex justifyContent="center">
            <Button onClick={onOpen} colorScheme="brandPrimary" w="full">
              <FormattedMessage defaultMessage="Contacter le propriétaire" />
            </Button>
          </Flex>
        )}
      </Stack>
    </>
  );
}
