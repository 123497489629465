import { defineMessage } from "react-intl";

/**
 * @param {object} params
 * @param {number} params.floor
 * @returns {import("react-intl").MessageDescriptor}
 */
export function getFloorMessage({ floor }) {
  if (floor < 0) {
    return defineMessage({
      defaultMessage: "Sous-sol {floor}",
      values: { floor },
    });
  }
  if (floor === 0) {
    return defineMessage({ defaultMessage: "Rez-de-chaussée" });
  }
  return defineMessage({
    defaultMessage:
      "{floor, selectordinal, one {#er} two {#ème} few {#ème} other {#ème}} étage",
    values: { floor },
  });
}
