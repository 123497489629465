const PROVIDER_VALUE_YOUTUBE = "youtube";
const PROVIDER_VALUE_DAILYMOTION = "dailymotion";
const PROVIDER_VALUE_VIMEO = "vimeo";

/** @type {Record<string, {name: string, urls: string[], getEmbedUrl: (param: URL) => string}>} */
const PROVIDERS = {
  [PROVIDER_VALUE_YOUTUBE]: {
    name: "youtube",
    urls: ["www.youtube.com", "youtu.be"],
    getEmbedUrl: (provider) => {
      const match = provider.href.match(
        /(?:youtu\.be\/|youtube\.com\/(?:watch\?v=|embed\/|v\/|.+\?v=))([^?&"'>]+)/,
      );
      if (match) {
        const videoId = match[1];
        return `https://www.youtube.com/embed/${videoId}`;
      }
      return provider.href;
    },
  },
  [PROVIDER_VALUE_DAILYMOTION]: {
    name: "dailymotion",
    urls: ["www.dailymotion.com", "dai.ly"],
    getEmbedUrl: (provider) => {
      const match = provider.href.match(
        /(?:dailymotion\.com\/video\/|dai\.ly\/)([^_?]+)/,
      );
      if (match) {
        const videoId = match[1];
        return `https://www.dailymotion.com/embed/video/${videoId}`;
      }
      return provider.href;
    },
  },
  [PROVIDER_VALUE_VIMEO]: {
    name: "vimeo",
    urls: ["www.vimeo.com", "player.vimeo.com", "vimeo.com"],
    getEmbedUrl: (provider) => {
      const match = provider.href.match(
        /(?:vimeo\.com\/|player\.vimeo\.com\/video\/)(\d+)/,
      );
      if (match) {
        const videoId = match[1];
        return `https://player.vimeo.com/video/${videoId}`;
      }
      return provider.href;
    },
  },
};

export const PROVIDER_LIST = Object.values(PROVIDERS);

/**
 * return the embed url of the given url
 * fait la traduction pour les provider youtube, dailymotion, vimeo.
 * @param {string} url
 * @returns {string}
 */
export function getEmbedUrl(url) {
  let _url = url;

  const _provider = new URL(url);
  const { host } = _provider;

  for (const provider of PROVIDER_LIST) {
    if (provider.urls.includes(host)) {
      _url = provider.getEmbedUrl(_provider);
    }
  }

  return _url;
}

/**
 * @param {object} param0
 * @param {import("../../../types/Hotel").HotelUpdateMediasDataFormValues} param0.fields
 */
export default function hotelSerializeUpdateMedias({ fields }) {
  return {
    data: {
      medias: fields.data.medias,
    },
  };
}
