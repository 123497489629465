import { Text } from "@chakra-ui/react";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef {object} PriceProps
 * @property {number} price
 * @property {"week" | "night"} durationUnit
 */

/**
 * @param {PriceProps} props
 */
export function HotelViewRoomPrice({ price, durationUnit }) {
  const intl = useIntl();

  return (
    <Text
      fontSize="0.875rem"
      fontWeight={400}
      lineHeight="0.625rem"
      color="gray.500">
      <FormattedMessage
        defaultMessage="Dès <b>{price}</b> / {duration, select, night {nuit} week {semaine} other {duration}}"
        values={{
          price: intl.formatNumber(price, {
            style: "currency",
            currency: "EUR",
            maximumFractionDigits: 0,
          }),
          duration: durationUnit,
          b: (chunks) => (
            <Text
              as="span"
              fontSize="1.3rem"
              fontWeight="400"
              color="brandPrimary.500">
              {chunks}
            </Text>
          ),
        }}
      />
    </Text>
  );
}
