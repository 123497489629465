import { Box, useColorModeValue } from "@chakra-ui/react";
import { Children, memo, useMemo } from "react";
import { RTableContextProvider } from "./RTableContext";
import { RTrContextProvider } from "./RTrContext";

/**
 * @typedef {object} RTableColumn
 * @property {import("react").ReactNode | string} title
 * @property {string} [size]
 * @property {"right"} [textAlign]
 */

export const RTable = memo(
  /**
   * @typedef {object} Props
   * @property {RTableColumn[]} [columns]
   * @property {string} [responsiveBreakpoint]
   * @property {boolean} [isStriped]
   * @property {boolean} [useDivider] weather or not to display a divider between rows on mobile when isStriped is set to false
   * @property {boolean} [useDesktopDivider] weather or not to display a divider between rows on desktop
   * @property {string} [cellPadding]
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function ResponsiveTable({
    columns: _columns,
    responsiveBreakpoint = "425px",
    isStriped = false,
    useDivider = true,
    useDesktopDivider = false,
    cellPadding = "8px",
    children,
  }) {
    const color = useColorModeValue("gray.700", "gray.300");

    const columns = useMemo(() => {
      /** @type {RTableColumn[]} */
      const columns = _columns ?? [];
      return columns;
    }, [_columns]);

    const childrenArray = useMemo(() => {
      return Children.toArray(children);
    }, [children]);

    return (
      <RTableContextProvider
        columns={columns}
        responsiveBreakpoint={responsiveBreakpoint}
        isStriped={isStriped}
        useDivider={useDivider}
        useDesktopDivider={useDesktopDivider}
        cellPadding={cellPadding}>
        <Box sx={{ containerType: "inline-size" }}>
          <Box
            display="table"
            w="full"
            sx={{
              [`@container (max-width: ${responsiveBreakpoint})`]: {
                "&": {
                  display: "block",
                },
              },
            }}>
            <Box
              display="table-header-group"
              sx={{
                [`@container (max-width: ${responsiveBreakpoint})`]: {
                  "&": {
                    display: "block",
                  },
                },
              }}>
              <Box
                display="table-row"
                sx={{
                  [`@container (max-width: ${responsiveBreakpoint})`]: {
                    "&": {
                      display: "none",
                    },
                  },
                }}>
                {columns.map((column, index) => (
                  <Box
                    key={index}
                    display="table-cell"
                    color={color}
                    px={cellPadding}
                    pt={isStriped ? cellPadding : undefined}
                    pb={
                      isStriped || useDesktopDivider ? cellPadding : undefined
                    }
                    w={column.size}
                    textAlign={column.textAlign}
                    textTransform="uppercase"
                    fontSize="xs"
                    borderBottomWidth={useDesktopDivider ? "1px" : undefined}
                    sx={{
                      "&:first-of-type": {
                        pl: isStriped ? `calc(${cellPadding} * 2)` : undefined,
                      },
                      "&:last-of-type": {
                        pr: isStriped ? `calc(${cellPadding} * 2)` : undefined,
                      },
                      [`@container (max-width: ${responsiveBreakpoint})`]: {
                        "&": {
                          width: "auto",
                        },
                      },
                    }}
                    _first={{
                      pl: 0,
                    }}
                    _last={{
                      pr: 0,
                    }}>
                    {column.title}
                  </Box>
                ))}
              </Box>
            </Box>

            <Box
              display="table-row-group"
              sx={{
                [`@container (max-width: ${responsiveBreakpoint})`]: {
                  "&": {
                    display: "block",
                  },
                },
              }}>
              {childrenArray.map((child, index) => {
                return (
                  <RTrContextProvider
                    isFirstRow={index === 0}
                    isLastRow={index === childrenArray.length - 1}
                    key={index}>
                    {child}
                  </RTrContextProvider>
                );
              })}
            </Box>
          </Box>
        </Box>
      </RTableContextProvider>
    );
  },
);
