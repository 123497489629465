import Error404Public from "../404Public";
import { Error500Public } from "../500Public";

/**
 * @typedef {object} Props
 * @property {number} [status]
 * @property {import("react").ReactNode} children
 */
/**
 * This component is used to display a 404 or 500 error page in the public area.
 * It is used to wrap the content of a page that can return a 404 or 500 error.
 * If the status code is 200 or undefined, it will display the children.
 * If the status code is 404 or 403, it will display the 404 error page.
 * If the status code is 500, it will display the 500 error page.
 * @param {Props} props
 */
export function PageErrorHandlerPublic({ status, children }) {
  return (
    <>
      {status === undefined || status === 200 ? (
        children
      ) : (
        <>
          {status === 404 || status === 403 ? (
            <Error404Public />
          ) : (
            <Error500Public />
          )}
        </>
      )}
    </>
  );
}
