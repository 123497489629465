import { Button, Center, Flex, Spinner, Text } from "@chakra-ui/react";
import { useSearchInfiniteContext } from "@raiden/library-ui/contexts/SearchInfinite/useSearchInfiniteContext";
import { Fragment } from "react";
import { useIntl } from "react-intl";
import { DataHandlerInfinite } from "@raiden/library-ui/components/DataHandlerInfinite";
import BookingReviewDetail from "@raiden/library-ui/components/Booking/Review/Detail";
import { Icon } from "@raiden/library-ui/components/Icon";

export function HotelViewReviewsResults() {
  /** @type {import("@raiden/library-ui/contexts/SearchInfinite/useSearchInfiniteContext").UseSearchInfiniteReturn<import(".").HotelsViewReviewSearchFormValues, import("@raiden/library-ui/types/CartBookingReview").CartBookingReview[]>} */
  const { swrInfiniteResponse } = useSearchInfiniteContext();

  const noMoreResults =
    swrInfiniteResponse?.data?.[0]?.meta?.last_page ===
      swrInfiniteResponse?.data?.length &&
    swrInfiniteResponse?.data?.[0]?.meta?.last_page >= 1;

  const intl = useIntl();

  return (
    <DataHandlerInfinite
      swrInfiniteResponse={swrInfiniteResponse}
      keepPreviousData={true}>
      {(pages) => (
        <>
          <Flex flexDir="column" gap="1rem">
            {pages?.map((page, pageIndex) => {
              return (
                <Fragment key={pageIndex}>
                  {page.data?.map((resource) => (
                    <BookingReviewDetail
                      bookingReview={resource}
                      key={resource.id}
                      withState={false}
                      withUser={false}
                      withOwnerResponse={true}
                    />
                  ))}
                </Fragment>
              );
            })}
          </Flex>

          {!noMoreResults && (
            <Center>
              {swrInfiniteResponse?.isValidating ? (
                <Spinner
                  size="lg"
                  emptyColor="gray.200"
                  color="blue.500"
                  transition="opacity 0.25s"
                />
              ) : (
                <Button
                  size="sm"
                  rightIcon={<Icon icon="ms_keyboard_arrow_down" />}
                  variant="outline"
                  onClick={() =>
                    swrInfiniteResponse.setSize((size) => size + 1)
                  }>
                  {intl.formatMessage({
                    defaultMessage: "Voir plus d'avis voyageurs",
                  })}
                </Button>
              )}
            </Center>
          )}

          {noMoreResults && (
            <Text variant="text-sm" textAlign="center">
              {intl.formatMessage({
                defaultMessage: "Il n'y a plus d'avis",
              })}
            </Text>
          )}
        </>
      )}
    </DataHandlerInfinite>
  );
}
