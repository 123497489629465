import { Box, useColorModeValue } from "@chakra-ui/react";
import { Children, memo, useContext } from "react";
import { RTableContext } from "./RTableContext";
import { RTdContextProvider } from "./RTdContext";

export const RTr = memo(
  /**
   * @typedef {object} Props
   * @property {import("react").ReactNode} children
   */
  /**
   * @param {Props} props
   */
  function RTr({ children }) {
    const { responsiveBreakpoint, isStriped, useDivider } =
      useContext(RTableContext);

    const backgroundColor = useColorModeValue("brandPrimary.50", "gray.700");

    return (
      <Box
        display="table-row"
        sx={{
          "&:nth-of-type(odd)": {
            backgroundColor: isStriped ? backgroundColor : undefined,
          },
          [`@container (max-width: ${responsiveBreakpoint})`]: {
            "&": {
              display: "block",
            },
            "&:not(:last-of-type)": {
              borderBottomWidth: isStriped
                ? undefined
                : useDivider
                ? "1px"
                : undefined,
            },
          },
        }}>
        {Children.toArray(children).map((child, index) => (
          <RTdContextProvider columnIndex={index} key={index}>
            {child}
          </RTdContextProvider>
        ))}
      </Box>
    );
  },
);
