import { Box, Button, Stack, Text } from "@chakra-ui/react";
import { Icon } from "@raiden/library-ui/components/Icon";
import useRequest from "@raiden/library-ui/hooks/useRequest";
import generateApiUrl from "@raiden/library-ui/libraries/utils/generateApiUrl";
import { useCallback } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { FormattedMessage, useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {number} customerId
 * @property {{data: import("@raiden/library-ui/types/Customer").CustomerPublicPhone} | null} phoneData
 * @property {React.Dispatch<React.SetStateAction<{data: import("@raiden/library-ui/types/Customer").CustomerPublicPhone} | null>>} setPhoneData
 */
/**
 * @param {Props} props
 */
export function WebHotelContactPublicPhone({
  customerId,
  phoneData,
  setPhoneData,
}) {
  const intl = useIntl();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const { request, isLoading: phoneIsLoading, toastError } = useRequest();

  const getPublicPhone = useCallback(async () => {
    if (!executeRecaptcha) {
      console.warn("Execute recaptcha not yet available");
      toastError({
        id: "invalid-recaptcha",
        status: "error",
        description: intl.formatMessage({
          defaultMessage: "Impossible de charger le module de sécurité",
        }),
        title: intl.formatMessage({
          defaultMessage: "Erreur",
        }),
        isClosable: true,
      });
      return;
    }

    const token = await executeRecaptcha("get_public_phone");

    request(
      generateApiUrl({
        id: "@customers.publicPhone",
        parameters: { customerId },
        query: { meta: { recaptcha_token: token } },
      }),
      {
        method: "GET",
      },
    )
      .then((response) => {
        setPhoneData(response);
      })
      .catch((error) => {
        console.log(error);

        toastError({
          title: intl.formatMessage({
            defaultMessage:
              "Une erreur est survenue lors de la récupération du numéro de téléphone.",
          }),
        });
      });
  }, [customerId, executeRecaptcha, intl, request, setPhoneData, toastError]);

  return (
    <Stack spacing=".75rem">
      <Text variant="h5">
        {intl.formatMessage({
          defaultMessage: "Contact par téléphone",
        })}
      </Text>

      <Text variant="text-md-light">
        {intl.formatMessage({
          defaultMessage:
            "Pour toute question ou demande concernant votre séjour, vous pouvez contacter directement le propriétaire par téléphone :",
        })}
      </Text>

      {phoneData ? (
        <Box w="300px" bg="gray.100" p=".5rem" borderRadius="md">
          {phoneData.data.public_phone1 && (
            <Text>
              {intl.formatMessage(
                {
                  defaultMessage: "Téléphone principal: {phone}",
                },
                {
                  phone: phoneData.data.public_phone1,
                },
              )}
            </Text>
          )}

          {phoneData.data.public_phone2 && (
            <Text>
              {intl.formatMessage(
                {
                  defaultMessage: "Téléphone secondaire: {phone}",
                },
                {
                  phone: phoneData.data.public_phone2,
                },
              )}
            </Text>
          )}
        </Box>
      ) : (
        <Button
          maxW="250px"
          colorScheme="brandPrimary"
          leftIcon={<Icon icon="ms_phone" />}
          isLoading={phoneIsLoading}
          onClick={getPublicPhone}>
          <FormattedMessage defaultMessage="Voir le numéro de téléphone" />
        </Button>
      )}
    </Stack>
  );
}
