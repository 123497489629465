import { Slider } from "@raiden/library-ui/components/Slider";
import { RoomPictureLazy } from "../../RoomPictureLazy/RoomPictureLazy";

/**
 * @typedef {object} Props
 * @property {import("@raiden/library-ui/types/RoomPicture").RoomPicture[]} pictures
 */
/**
 * @param {Props} props
 */
export function SliderImages({ pictures }) {
  return (
    <Slider
      itemWidth="100%"
      gradientColor="transparent"
      withPadding={false}
      revealButtonsOnHover={true}>
      {pictures.map((picture) => (
        <RoomPictureLazy roomPicture={picture} key={picture.id} />
      ))}
    </Slider>
  );
}
