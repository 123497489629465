import { HStack } from "@chakra-ui/react";
import { memo } from "react";
import { Icon } from "@raiden/library-ui/components/Icon";

export const LabelRanking = memo(
  /**
   * @typedef {object} Props
   * @property {number} labelRanking
   */
  /**
   * @param {Props} props
   */
  function LabelRanking({ labelRanking }) {
    return (
      <HStack
        display="inline-flex"
        spacing="0"
        backgroundColor="orange.500"
        color="#fff"
        borderRadius="30px"
        whiteSpace="nowrap"
        px="0.5rem"
        py="0.1875rem">
        {Array.from({ length: labelRanking }).map((_, index) => (
          <Icon icon="sf_key" color="inherit" key={index} />
        ))}
      </HStack>
    );
  },
);
